import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CommuteIcon from '@mui/icons-material/Commute';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import {
  Box, Stack, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { QuoteStatusBadge } from '../QuoteStatusBadge';

interface IconTextProps {
  icon: React.ReactNode;
  text: string;
}

function IconText(props: IconTextProps) {
  const { icon, text } = props;
  return (
    <Box display="flex" alignItems="center">
      {icon}
      <Typography ml="0.5rem" fontSize="0.9rem">{text}</Typography>
    </Box>
  );
}

interface Props {
  quote: CondensedQuote
}

export function QuotePreview(props: Props) {
  const { quote } = props;
  const navigate = useNavigate();

  const getPointDisplayText = (point: RoutePoint): string => {
    if (point?.point_location) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
      const { point_location } = point;
      return `${point_location?.city ? `${point_location.city},` : ''} ${point_location?.province ? `${point_location.province},` : ''} ${point_location?.country ?? ''}`;
    }
    return '-';
  };

  return (
    <Box
      display="flex"
      px="2rem"
      py="1rem"
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
          title: 'View Quote',
        },
        m: '.5rem',
      }}
      onClick={() => navigate(`/shipments/${quote.shipment}?focus=quotes`)}

    >
      <Box>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body1">Status: </Typography>
          <QuoteStatusBadge status={quote?.state ?? 'unknown'} />
        </Stack>
        <Box sx={{ mt: '1rem' }}>
          <IconText
            icon={<LocalShippingIcon />}
            text={`Shipment ID: ${quote?.shipment ?? '-'}`}
          />
        </Box>

      </Box>
      <Box ml="2rem">
        <IconText
          icon={<TripOriginIcon />}
          text={`Origin: ${getPointDisplayText(quote.origin)}`}
        />
        <Box sx={{ mt: '1rem' }}>
          <IconText
            icon={<FmdGoodIcon />}
            text={`Desination: ${getPointDisplayText(quote.destination)}`}
          />
        </Box>
      </Box>
      <Box ml="2rem" flex={1}>
        <IconText
          icon={<CommuteIcon />}
          text={`Equipment: ${quote?.equipment_type ?? '-'}`}
        />
        <Box sx={{ mt: '1rem' }}>
          <IconText
            icon={<AttachMoneyIcon />}
            text={`Amount: ${quote?.price ?? '-'}`}
          />
        </Box>

      </Box>
    </Box>
  );
}
