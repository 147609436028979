import { useRoleContext } from 'hooks';
import { useShipmentFiltersContext } from 'hooks/useShipmentFiltersContext';
import { useEffect, useState } from 'react';
import {
  CARRIER_FILTER_DISPLAY_VALUES, INITIAL_FILTER_STATE, SHIPPER_FILTER_DISPLAY_VALUES,
} from './constants';
import { ShipmentsFilter } from './ShipmentsFilter';
import {
  DateWindow, Location,
  ShipmentsFilterDisplay,
  ShipmentsFilterState,
} from './types';

interface Props {
  title?: string;
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  readFromContext?: boolean;
  resetReadFromContext?: VoidFunction;
}

export function ShipmentsFilterContainer(props: Props) {
  const {
    title, isOpen, onOpen, onClose, readFromContext, resetReadFromContext,
  } = props;
  const [filterState, setFilterState] = useState<ShipmentsFilterState>(INITIAL_FILTER_STATE);
  const [
    filterDisplayValues,
    setFilterDisplayValues,
  ] = useState<ShipmentsFilterDisplay>(SHIPPER_FILTER_DISPLAY_VALUES);
  const {
    updatePickupStartTime,
    updatePickupEndTime,
    updateOrigin,
    updateDestination,
    updateOrderBy,
    updateEquipmentType,
    updateServiceType,
    updateShipmentStatus,
    updateCommodity,
    filters,
  } = useShipmentFiltersContext();
  const { role } = useRoleContext();

  const handleValueChange = (key: keyof ShipmentsFilterState, value: string): void => {
    setFilterState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleLocationChange = (
    key: keyof Location,
    addressKey: keyof ShipmentLocationFilter,
    value: string,
  ): void => {
    if (key === 'origin') {
      updateOrigin(addressKey, value);
    } else if (key === 'destination') {
      updateDestination(addressKey, value);
    }
    setFilterState((prev) => ({
      ...prev,
      [key]: {
        ...filterState[key],
        [addressKey]: value,
      },
    }));
  };

  const handleDateWindowChange = (key: keyof DateWindow, value: string): void => {
    if (key === 'pickupStartTime') {
      updatePickupStartTime(value);
    } else if (key === 'pickupEndTime') {
      updatePickupEndTime(value);
    }
    handleValueChange(key, value);
  };

  const handleOrderByChange = (key: keyof ShipmentsFilterState, value: string): void => {
    let orderBy = '';
    if (key === 'orderBy') {
      let sortOrderPrefix = '';
      if (filterState.sortOrder === 'DESC') {
        sortOrderPrefix = '-';
      }
      orderBy = `${sortOrderPrefix}${value}`;
    } else if (key === 'sortOrder') {
      let sortOrderPrefix = '';
      if (value === 'DESC') {
        sortOrderPrefix = '-';
      }
      orderBy = `${sortOrderPrefix}${filterState.orderBy}`;
    }
    updateOrderBy(orderBy);
    handleValueChange(key, value);
  };

  const handleEquipmentTypeChange = (value: string): void => {
    updateEquipmentType(value);
    handleValueChange('equipmentType', value);
  };

  const handleServiceTypeChange = (value: string): void => {
    updateServiceType(value);
    handleValueChange('service', value);
  };

  const handleShipmentStatusChange = (value: string): void => {
    updateShipmentStatus(value as ShipmentStatusValue);
    handleValueChange('shipmentStatus', value);
  };

  const handleCommodityChange = (value: Commodity | null): void => {
    updateCommodity(value);
    setFilterState((prev) => ({
      ...prev,
      commodity: value,
    }));
  };

  const toggleFilterTools = (): void => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    }
  };

  useEffect(() => {
    if (role?.type === 'CARRIER') {
      setFilterDisplayValues(CARRIER_FILTER_DISPLAY_VALUES);
      setFilterState((prev) => ({
        ...prev,
        shipmentStatus: 'ACTIVE',
      }));
    } else if (role?.type === 'SHIPPER') {
      setFilterDisplayValues(SHIPPER_FILTER_DISPLAY_VALUES);
    }
  }, [role]);

  useEffect(() => {
    if (readFromContext && resetReadFromContext !== undefined) {
      const savedFilterState: ShipmentsFilterState = {
        equipmentType: filters?.equipment_type ? filters.equipment_type : 'ALL',
        service: filters?.service ? filters.service : 'ALL',
        commodity: filters?.commodity ? filters.commodity : null,
        shipmentStatus: filters?.status ? filters.status : 'ALL',
        origin: {
          city: filters?.origin?.city ? filters.origin.city : '',
          province: filters?.origin?.province ? filters.origin.province : '',
          country: filters?.origin?.country ? filters.origin.country : '',
        },
        destination: {
          city: filters?.destination?.city ? filters.destination.city : '',
          province: filters?.destination?.province ? filters.destination.province : '',
          country: filters?.destination?.country ? filters.destination.country : '',
        },
        pickupStartTime: filters?.pickup_window?.pickup_start ? filters.pickup_window.pickup_start : '',
        pickupEndTime: filters?.pickup_window?.pickup_end ? filters.pickup_window.pickup_end : '',
        orderBy: 'created_at',
        sortOrder: 'DESC',
      };
      setFilterState(savedFilterState);
      resetReadFromContext();
    }
  }, [readFromContext, filters, resetReadFromContext]);

  if (role && (role.type === 'CARRIER' || role.type === 'SHIPPER')) {
    return (
      <ShipmentsFilter
        title={title}
        filters={filterState}
        displayedFilters={filterDisplayValues}
        filterToolsAreVisible={isOpen}
        toggleFilterTools={toggleFilterTools}
        onLocationChanged={handleLocationChange}
        onDateWindowChanged={handleDateWindowChange}
        onOrderChanged={handleOrderByChange}
        onEquipmentTypeChanged={handleEquipmentTypeChange}
        onServiceTypeChanged={handleServiceTypeChange}
        onShipmentStatusChanged={handleShipmentStatusChange}
        onCommodityChanged={handleCommodityChange}
      />
    );
  }
  return (
    <div />
  );
}

ShipmentsFilterContainer.defaultProps = {
  title: 'Filters',
  readFromContext: false,
  resetReadFromContext: () => null,
};
