import { Typography } from '@mui/material';
import { useRoleContext } from '../../hooks';
import { ShipmentQuoteList as CarrierShipmentQuoteList } from '../Carrier/components/ShipmentQuoteList';
import { ShipmentQuoteList as ShipperShipmentQuoteList } from '../Shipper/components/ShipmentQuoteList';

interface Props {
  shipment: CondensedShipment | undefined;
}

export function ShipmentQuoteListContainer(props: Props) {
  const { role } = useRoleContext();
  const { shipment } = props;

  if (role === null || !shipment) {
    return <div />;
  }

  let quoteListComponent;
  switch (role.type) {
    case 'SHIPPER':
      quoteListComponent = (
        <ShipperShipmentQuoteList shipment={shipment as ShipperCondensedShipment} />
      );
      break;
    case 'CARRIER':
      quoteListComponent = (
        <CarrierShipmentQuoteList shipment={shipment} />
      );
      break;
    default:
      quoteListComponent = <Typography variant="h1">Invalid role. Please contact us for assistance.</Typography>;
      break;
  }

  return (
    quoteListComponent
  );
}
