import {
  Box, Tab, Tabs,
} from '@mui/material';
import * as React from 'react';
import { useSearchParams } from 'react-router-dom';
import { QuoteTab } from './components/QuoteTab';
import { RouteTab } from './components/RouteTab';
import { ShipmentQATab } from './components/ShipmentQATab';

interface Props {
  shipment: CondensedShipment | undefined;
}

export function ShipmentOverviewTab(props: Props) {
  const { shipment } = props;
  const [searchParams] = useSearchParams();

  const tabs = {
    routes: 'routes',
    quotes: 'quotes',
    qa: 'qa',
  };

  let focus = searchParams.get('focus') ?? tabs.routes;
  if (!(focus in tabs)) {
    focus = tabs.routes;
  }

  const [selectedTab, setSelectedTab] = React.useState<string>(focus);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={selectedTab} onChange={handleChange} variant="fullWidth">
        <Tab label={tabs.routes} value={tabs.routes} />
        <Tab label={tabs.quotes} value={tabs.quotes} />
        <Tab label={tabs.qa} value={tabs.qa} />
      </Tabs>
      {selectedTab === tabs.routes && <RouteTab shipment={shipment} />}
      {selectedTab === tabs.quotes && <QuoteTab shipment={shipment} />}
      {selectedTab === tabs.qa && <ShipmentQATab shipment={shipment} />}
    </Box>
  );
}
