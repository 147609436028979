import { Box } from '@mui/material';
import { useEffect } from 'react';
import {
  Route, Routes, useNavigate,
} from 'react-router-dom';
import { Authentication } from './components/Authentication';
import { CompanySetup } from './components/CompanySetup';
import { ContentSwitcher } from './components/ContentSwitcher';
import { Shipment } from './components/Shipment';
import { RoleProvider } from './context';
import { useAuthContext } from './hooks';
import { AppLayout } from './layouts';
import { userBelongsToCompany } from './utils';

function App() {
  const { isAuthenticated, user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && user != null) {
      if (!userBelongsToCompany(user) && window.location.pathname !== '/setup-company') {
        navigate('/setup-company');
      } else if (userBelongsToCompany(user) && window.location.pathname === '/setup-company') {
        navigate('/');
      }
    }
  }, [isAuthenticated, user, navigate]);

  const showLogin = !isAuthenticated || user == null;

  return (
    <Box>
      {showLogin ? (
        <Authentication />
      ) : (
        <RoleProvider>
          <AppLayout content={(
            <Routes>
              <Route path="/" element={<ContentSwitcher />} />
              <Route path="/shipments/:id" element={<Shipment />} />
              <Route path="/setup-company" element={<CompanySetup />} />
            </Routes>
          )}
          />
        </RoleProvider>
      )}
    </Box>
  );
}

export default App;
