import { Box, BoxProps } from '@mui/material';

interface Props {
  children: React.ReactNode;
  title?: React.ReactNode;
  style?: BoxProps;
}

export function DashboardItemLayout(props: Props) {
  const { children, style, title } = props;

  return (
    <Box boxShadow={1} borderRadius={4} sx={{ background: 'white', ...style }}>
      {title}
      {children}
    </Box>
  );
}

DashboardItemLayout.defaultProps = {
  style: {},
  title: null,
};
