export const TERMS_OF_SERVICE_URL = 'https://evotrux.com/terms';
export const BG_ICON_COLOR = '#0d2e45';
export const METER_MILE_CONVERSION_FACTOR = 1609.344;

/*
Orders Quote states by relevance to the user.
The higher the index in the list, the higher the importance
For example, for a shipment with mulitple quotes, an accepted
quote will be at the top of the list of quotes.

To sort quotes with priority values use orderQuotesByQuoteStatus()
in frontend/src/utils/index.ts
*/
export const QUOTE_STATUS_PRIORITY_LIST = [
  'ACCEPTED',
  'ACTIVE',
  'COUNTERED',
  'CANCELLED',
  'EXPIRED',
  'SHIPMENT_LOST',
  'SHIPMENT_CANCELLED',
  'SHIPMENT_EXPIRED',
];
