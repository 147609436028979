import {
  Box, CircularProgress, Divider, Stack, Typography,
} from '@mui/material';
import { QuotePreview } from '../../../QuotePreview';

interface Props {
  quotes: CondensedQuote[];
  loading: boolean;
  error: string | null;
}

export function CarrierQuoteList(props: Props) {
  const { quotes, loading, error } = props;
  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  if (quotes.length === 0) {
    return (
      <Typography sx={{ ml: '2rem' }} variant="body1" textAlign="center">
        You do not have any quotes.
      </Typography>
    );
  }
  if (error != null) {
    return (
      <Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    );
  }
  return (
    <Stack direction="column" justifyContent="center">
      {quotes.map((quote) => (
        <Box key={quote.quote_action}>
          <Divider />
          <QuotePreview quote={quote} />
          <Divider />
        </Box>
      ))}
    </Stack>
  );
}
