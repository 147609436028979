import { Typography } from '@mui/material';
import { CounterQuoteInteraction } from '../CounterQuoteInteraction';
import { SimpleQuoteInteraction } from '../SimpleQuoteInteraction';

interface Props{
  quoteId: number;
  action: CondensedQuoteActionValue | null;
  onClose: VoidFunction;
}

export function QuoteActionModal(props: Props) {
  const {
    quoteId, onClose, action,
  } = props;
  let quoteActionFormComponent;
  switch (action) {
    case 'CANCEL':
    case 'ACCEPT':
      quoteActionFormComponent = (
        <SimpleQuoteInteraction
          type={action}
          quoteId={quoteId}
          onClose={onClose}
        />
      );
      break;
    case 'COUNTER':
      quoteActionFormComponent = <CounterQuoteInteraction quoteId={quoteId} onClose={onClose} />;
      break;
    default:
      quoteActionFormComponent = <Typography color="error" variant="body1">Invalid action. Please contact us for assistance.</Typography>;
      break;
  }

  return quoteActionFormComponent;
}
