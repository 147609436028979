import AddIcon from '@mui/icons-material/Add';
import {
  Box, Button, Divider, InputAdornment, Stack, TextField, Typography,
} from '@mui/material';
import { PersonalInformationDisclaimer } from 'components/PersonalInformationDisclaimer';
import { ServiceTypeSelect } from 'components/ServiceTypeSelect';
import { CommoditySelectInput } from '../../../CommoditySelectInput';
import { CurrencySelect } from '../../../CurrencySelect';
import { EquipmentTypeSelect } from '../../../EquipmentTypeSelect';
import { NumberInput } from '../../../NumberInput';
import { AddStopButton } from './components/AddStopButton';
import { CreateShipmentFormState } from './types';

const selectWrapperStyle = { mr: '1rem', mt: '1rem' };
const dividerStyle = { my: 4 };

interface Props {
  routePoints: React.ReactNode[];
  routeDistance: number;
  serviceType: string;
  commodity: Commodity | null;
  error: string | null;
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  onValueChanged: <T,>(key: keyof CreateShipmentFormState, value: T | null) => void;
  toggleCreateRouteOpen: VoidFunction;
  onSubmit: VoidFunction;
}

export function CreateShipmentForm(props: Props) {
  const {
    error,
    routePoints,
    routeDistance,
    serviceType,
    commodity,
    onSubmit,
    onValueChanged,
    toggleCreateRouteOpen,
  } = props;

  const onEquipmentTypeChanged = (newValue: string) => {
    onValueChanged<string>('equipmentType', newValue);
  };
  const onServiceChanged = (newValue: string) => {
    onValueChanged<string>('service', newValue);
    if (newValue === 'TRUCK_LOAD') {
      onValueChanged<string>('footage', '');
    }
  };
  const onCurrencyChanged = (newValue: string) => {
    onValueChanged<string>('currency', newValue);
  };

  const onCommoditySelected = (newValue: Commodity | null) => {
    onValueChanged<Commodity>('commodity', newValue);
  };

  return (
    <Box>
      <Stack direction="row" flexWrap="wrap" mb={4} alignItems="flex-end">
        <Box sx={selectWrapperStyle}>
          <EquipmentTypeSelect onChange={onEquipmentTypeChanged} />
        </Box>
        <Box sx={selectWrapperStyle}>
          <ServiceTypeSelect onChange={onServiceChanged} />
        </Box>
        <CommoditySelectInput
          commodityValue={commodity}
          onCommoditySelected={onCommoditySelected}
          textFieldProps={{ required: true, label: 'Commodity' }}
        />
      </Stack>
      <Stack direction="row" spacing={4} my={4}>
        {!(serviceType === 'TRUCK_LOAD') && (
          <NumberInput
            inputLabel="Footage"
            defaultValue={0.0}
            onChange={(val: number) => onValueChanged<number>('footage', val)}
            endAdornment={<InputAdornment position="end">ft</InputAdornment>}
          />
        )}
        <NumberInput
          inputLabel="Weight"
          defaultValue={0.0}
          onChange={(val: number) => onValueChanged<number>('weightLb', val)}
          endAdornment={<InputAdornment position="end">lb</InputAdornment>}
        />
      </Stack>
      <Stack direction="row" spacing={4} mt={2}>
        <NumberInput
          inputLabel="Preferred Amount"
          defaultValue={0.0}
          onChange={(val: number) => onValueChanged<number>('preferredPrice', val)}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
        <CurrencySelect onChange={onCurrencyChanged} />
      </Stack>
      <Stack mt="2rem" spacing={4} direction="row">
        <TextField
          multiline
          label="Special Requirements"
          onChange={(event) => onValueChanged<string>('specialRequirements', event.target.value)}
          sx={{ width: '50%' }}
        />
        <TextField
          multiline
          label="Additional Details"
          onChange={(event) => onValueChanged<string>('additionalDetails', event.target.value)}
          sx={{ width: '50%' }}
        />
      </Stack>
      <PersonalInformationDisclaimer />
      <Divider sx={dividerStyle} />
      <Box>
        <Box display="flex">
          <Box>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">Create Route</Typography>
              <Typography color="gray">
                (Current Distance
              </Typography>
              <Typography color="gray" fontWeight="bold">
                {routeDistance.toFixed(2)}
                {' '}
                mi.)
              </Typography>
            </Stack>

            <Typography color="#b6b6b6">*Drag to Reorganize Route Stops</Typography>
          </Box>
          <AddStopButton onClick={toggleCreateRouteOpen} />
        </Box>
        <Stack py="1rem" spacing={1}>
          {routePoints}
        </Stack>

      </Box>
      {error != null && <Typography sx={{ mt: '1rem', mb: '1rem' }} color="red">{error}</Typography>}
      <Button variant="contained" size="large" onClick={onSubmit}>
        <AddIcon />
        Post Shipment
      </Button>
    </Box>
  );
}
