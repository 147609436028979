import {
  Chip,
} from '@mui/material';

interface Props {
  status: QuoteStatusValue
}

export function QuoteStatusBadge(props: Props) {
  const { status } = props;

  const getBadgeColor = (): 'primary' | 'success' | 'warning' | 'info' | 'error' => {
    switch (status) {
      case 'COUNTERED':
      case 'ACTIVE':
        return 'primary';
      case 'ACCEPTED':
        return 'success';
      case 'CANCELLED':
        return 'error';
      case 'SHIPMENT_LOST':
      case 'SHIPMENT_CANCELLED':
      case 'SHIPMENT_EXPIRED':
      case 'EXPIRED':
        return 'warning';
      default:
        return 'info';
    }
  };

  return <Chip label={status.replaceAll('_', ' ') ?? 'unknown'} color={getBadgeColor()} />;
}
