import {
  Box, Button, Stack, Typography,
} from '@mui/material';
import { useAPI, useRoleContext } from 'hooks';
import { useEffect, useState } from 'react';
import { ShipmentSearchList } from './ShipmentSearchList';

interface GetShipmentSearchResponse {
  results: ShipmentSearch[];
}

interface Props {
  isOpen: boolean;
  onOpen: VoidFunction;
  onClose: VoidFunction;
  onSavedSearchClick: VoidFunction;
}

export function ShipmentSearchListContainer(props: Props) {
  const {
    isOpen, onOpen, onClose, onSavedSearchClick,
  } = props;
  const { role } = useRoleContext();
  const [shipmentSearches, setShipmentSearches] = useState<ShipmentSearch[]>([]);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const { api } = useAPI();

  useEffect(() => {
    if (loading) {
      api<GetShipmentSearchResponse>('get', `/carriers/${role?.id}/shipment-searches`).then((response) => {
        setShipmentSearches(response.data?.results);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch shipment searches');
      }).finally(() => { setIsLoading(false); });
    }
  }, [loading, role, api]);

  return (
    <Box m="0rem">
      <Stack direction="row" m="1rem">
        <Typography variant="h6">Saved Searches</Typography>
        <Box display="flex" ml="auto">
          <Button
            sx={{
              mb: '1rem',
            }}
            variant="contained"
            onClick={isOpen ? onClose : onOpen}
          >
            {isOpen ? 'Hide saved searches' : 'Show saved searches'}
          </Button>
        </Box>
      </Stack>
      <ShipmentSearchList
        shipmentSearches={shipmentSearches}
        error={error}
        loading={loading}
        isOpen={isOpen}
        onSavedSearchClick={onSavedSearchClick}
      />
    </Box>
  );
}
