import { useAPI, useRoleContext } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { ShipmentQATab } from './ShipmentQATab';
import { GetShipmentQAResponse } from './types';

interface Props {
  shipment: CondensedShipment | undefined
}

export function ShipmentQATabContainer(props: Props) {
  const { shipment } = props;
  const { api } = useAPI();
  const [error, setError] = useState<null | string>(null);
  const [qaList, setQAList] = useState<ShipmentQA[]>([]);
  const [loading, setIsLoading] = useState<boolean>(true);
  const shipmentRef = useRef<undefined | CondensedShipment>();
  const { role } = useRoleContext();

  useEffect(() => {
    /*
    This hook keeps track of the shipment ID between renders to ensure
    that the QA is re-fetched when another shipment is selected.
    */
    if (shipment && shipmentRef.current?.info?.id !== shipment.info.id) {
      shipmentRef.current = shipment;
      setIsLoading(true);
    }
  }, [shipment]);

  useEffect(() => {
    if (loading && shipment) {
      api<GetShipmentQAResponse>('get', `/shipments/${shipment.info.id}/qa`).then((response) => {
        setQAList(response.data.qa);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch shipment Q&A');
      });
      setIsLoading(false);
    }
  }, [loading, api, shipment]);

  if (!shipment) {
    return <div />;
  }

  return (
    <ShipmentQATab
      loading={loading}
      error={error}
      renderSubmitQuestion={role != null && role.type === 'CARRIER'}
      qaList={qaList}
      shipment={shipment}
    />
  );
}
