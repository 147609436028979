import CommuteIcon from '@mui/icons-material/Commute';
import {
  Box, Divider, Stack, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatText, lbToKg } from '../../utils';

interface Props {
  shipment: CondensedShipment;
}

export function ShipmentPreview(props: Props) {
  const navigate = useNavigate();
  const { shipment } = props;
  const shipmentInfo = shipment.info;
  const firstPointLocation = shipment?.first_point?.point_location;
  const lastPointLocation = shipment?.last_point?.point_location;

  return (
    <Box
      display="flex"
      px="2rem"
      py="1rem"
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
        },
      }}
      onClick={() => navigate(`/shipments/${shipmentInfo.id}`)}
    >
      <Box>
        <Box display="flex" alignItems="center">
          <CommuteIcon />
          <Typography sx={{ textTransform: 'capitalize', ml: '0.5rem' }} variant="h6">{formatText(shipmentInfo.equipment_type)}</Typography>
        </Box>
        <Typography py="0.5rem" sx={{ textTransform: 'capitalize' }}>{formatText(shipmentInfo.service)}</Typography>
        <Typography>{shipmentInfo?.id ? `Shipment ID: ${shipmentInfo.id}` : ''}</Typography>
      </Box>
      <Box
        ml="2rem"
        flex={1}
        alignSelf="center"
        textAlign="center"
      >
        <Typography
          sx={{ textTransform: 'capitalize', ml: '0.5rem' }}
          variant="body1"
        >
          {formatText(shipmentInfo.commodity.name)}
        </Typography>
        <Stack my="0.5rem" direction="row" alignItems="center">
          {firstPointLocation?.city && firstPointLocation?.province
            ? (
              <Typography>
                {firstPointLocation.city}
                ,
                {' '}
                {firstPointLocation.province}
              </Typography>
            ) : '- , -'}
          <Box flex={1} mx="1rem">
            <Divider />
          </Box>
          {lastPointLocation?.city && lastPointLocation?.province
            ? (
              <Typography>
                {lastPointLocation.city}
                ,
                {' '}
                {lastPointLocation.province}
              </Typography>
            ) : '- , -'}
        </Stack>
      </Box>
      <Box mx="2rem" justifySelf="flex-end" alignSelf="center">
        <Typography>
          {parseFloat(shipmentInfo.weight_lb.toString()).toFixed(1)}
          {' '}
          lb
          {' '}
          (
          {lbToKg(parseFloat(shipmentInfo.weight_lb.toString())).toFixed(1)}
          kg)
        </Typography>
        <Typography>
          {shipmentInfo.footage != null ? `${parseFloat(shipmentInfo.footage.toString()).toFixed(1)} ft` : '-'}
          {' '}
          ft
        </Typography>
        <Typography>
          $
          {' '}
          {parseFloat(shipmentInfo.preferred_price.toString()).toFixed(2)}
        </Typography>
      </Box>
    </Box>
  );
}
