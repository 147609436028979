import { useState } from 'react';
import {
  OutlinedInput, FormControl, InputLabel,
} from '@mui/material';

interface Props {
  defaultValue: number;
  inputLabel: string;
  onChange: (value: number) => void;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

export function NumberInput(props: Props) {
  const {
    startAdornment, endAdornment, defaultValue, inputLabel, onChange,
  } = props;
  const [value, setValue] = useState<string>(defaultValue.toString());

  return (
    <FormControl>
      <InputLabel htmlFor={`number-input-${inputLabel}`}>{inputLabel}</InputLabel>
      <OutlinedInput
        id={`number-input-${inputLabel}`}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(parseFloat(e.target.value));
        }}
        startAdornment={startAdornment ?? null}
        endAdornment={endAdornment ?? null}
        label={inputLabel}
      />
    </FormControl>
  );
}

NumberInput.defaultProps = {
  startAdornment: null,
  endAdornment: null,
};
