import {
  Grid, Stack, Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { ISOStringToLocalReadableTimestamp } from '../../../../utils';
import { QuoteStatusBadge } from '../../../QuoteStatusBadge';

interface Props {
  quote: CondensedQuote;
}

export function QuoteDetail(props: Props) {
  const {
    quote,
  } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography>
              Quote Status:
              {' '}
            </Typography>
            <QuoteStatusBadge status={quote.state} />
          </Stack>
          <Typography>
            Submitted by:
            {' '}
            {quote?.created_by?.first_name}
            {' '}
            {quote?.created_by?.last_name}
          </Typography>
          <Typography>
            Company:
            {' '}
            {quote?.company?.name ?? '-'}
          </Typography>
          <Typography>
            Price: $
            {quote?.price ?? '-'}
            {' '}
            {quote?.currency ?? '-'}
          </Typography>
          <Typography>
            Equipment:
            {' '}
            {quote?.equipment_type ?? '-'}
          </Typography>
          <Typography>
            Created:
            {' '}
            {quote?.created_at ? ISOStringToLocalReadableTimestamp(quote.created_at) : '-'}
          </Typography>
          <Typography>
            Expires:
            {' '}
            { quote?.expires_at ? ISOStringToLocalReadableTimestamp(quote.expires_at) : '-'}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Typography variant="body2">
            Last Updated:
            {' '}
            {ISOStringToLocalReadableTimestamp(DateTime.now().toISO())}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
