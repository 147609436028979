import {
  Box, Chip, Divider, Stack, Typography,
} from '@mui/material';
import { getReadableRoutePointLocation, ISOStringToLocalReadableTimestamp } from '../../../utils';

interface Props {
  routePoint: RoutePoint;
}

export function RoutePointDetail(props: Props) {
  const { routePoint } = props;

  const getPointTimestamps = () => {
    const startLabel = routePoint.stop_operation === 'PICKUP' ? 'Pickup start' : 'Drop off start';
    const endLabel = routePoint.stop_operation === 'PICKUP' ? 'Pickup end' : 'Drop off end';
    return (
      <Stack direction="column" spacing={1} mr="1rem">
        <Typography textAlign="right">{`${startLabel}: ${ISOStringToLocalReadableTimestamp(routePoint.start_time)}`}</Typography>
        <Typography textAlign="right">{`${endLabel}: ${ISOStringToLocalReadableTimestamp(routePoint.end_time)}`}</Typography>
      </Stack>
    );
  };

  return (
    <div>
      <Stack pt="1rem" pb="1rem" direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row">
          <Chip
            sx={{ minWidth: '50px', mr: '1rem' }}
            color="primary"
            label={routePoint?.point_number ? `${routePoint?.point_number}` : '-'}
          />
          <Chip sx={{ minWidth: '50px', mr: '1rem' }} variant="outlined" label={routePoint?.stop_operation ?? '-'} />
          <Typography variant="h6">{getReadableRoutePointLocation(routePoint)}</Typography>
        </Stack>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Box>
            {getPointTimestamps()}
          </Box>
        </Stack>
      </Stack>
      <Divider />
    </div>
  );
}
