import { Box } from '@mui/material';
import { CompactShipmentPreview } from '../ShipmentPreview';

interface Props {
  shipments: CondensedShipment[];
}

export function QuickShipmentsBrowser(props: Props) {
  const { shipments } = props;
  return (
    <Box boxShadow={1}>
      {shipments.map((s) => (
        <CompactShipmentPreview key={s.info.id} shipment={s} />
      ))}
    </Box>
  );
}
