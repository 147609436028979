import { ShipmentFiltersProvider } from 'context';
import React from 'react';
import { ShipmentList } from '../ShipmentListContainer';
import { ShipmentSearchList } from '../ShipmentSearchList';

interface Props {
  scrollToTop: VoidFunction;
}

export function ShipmentFilterSavedSearch(props: Props) {
  const { scrollToTop } = props;
  const [isFilterOpen, setIsFilterOpen] = React.useState<boolean>(false);
  const [isSavedSearchOpen, setIsSavedSearchOpen] = React.useState<boolean>(false);
  const [readFromContext, setReadFromContext] = React.useState<boolean>(false);

  const resetReadFromContext = (): void => {
    setReadFromContext(false);
  };

  const onFilterOpen = (): void => {
    setIsFilterOpen(true);
  };

  const onFilterClose = (): void => {
    setIsFilterOpen(false);
  };

  const onSavedSearchOpen = (): void => {
    setIsSavedSearchOpen(true);
  };

  const onSavedSearchClose = (): void => {
    setIsSavedSearchOpen(false);
  };

  const onSavedSearchClick = (): void => {
    onSavedSearchClose();
    setReadFromContext(true);
    onFilterOpen();
    scrollToTop();
  };

  return (
    <ShipmentFiltersProvider>
      <ShipmentSearchList
        isOpen={isSavedSearchOpen}
        onOpen={onSavedSearchOpen}
        onClose={onSavedSearchClose}
        onSavedSearchClick={onSavedSearchClick}
      />
      <ShipmentList
        isFilterOpen={isFilterOpen}
        onFilterOpen={onFilterOpen}
        onFilterClose={onFilterClose}
        readFromContext={readFromContext}
        resetReadFromContext={resetReadFromContext}
      />
    </ShipmentFiltersProvider>
  );
}
