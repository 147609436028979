import { useAPI } from 'hooks';
import { useEffect, useState } from 'react';
import { ShipmentQuoteList } from './ShipmentQuoteList';

interface Props {
  shipment: CondensedShipment;
}

export function ShipmentQuoteListContainer(props: Props) {
  const { shipment } = props;
  const [quotes, setQuotes] = useState<CondensedQuote[]>([]);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const { api } = useAPI();

  useEffect(() => {
    if (loading) {
      api<CondensedQuote[]>('get', `/shipments/${shipment.info.id}/quotes/condensed`).then((response) => {
        setQuotes(response.data);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch quotes');
      }).finally(() => { setIsLoading(false); });
    }
  }, [loading, shipment, api]);

  return <ShipmentQuoteList quotes={quotes} loading={loading} error={error} />;
}
