import { Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { DashboardItemLayout, PageLayout } from '../../../../layouts';
import { CarrierQuoteList } from '../CarrierQuoteList';
import { ShipmentFilterSavedSearch } from '../ShipmentFilterSavedSearch';

export function Dashboard() {
  const ref = useRef<HTMLDivElement | null>(null);

  const scrollToTop = () => {
    ref?.current?.scrollIntoView();
  };

  return (
    <PageLayout>
      <div style={{ display: 'hidden' }} ref={ref} />
      <Typography variant="h4" fontWeight={600} mb="2rem">Shipments</Typography>
      <Stack direction="row" spacing={8}>
        <DashboardItemLayout style={{ width: '50%' }}>
          <ShipmentFilterSavedSearch scrollToTop={scrollToTop} />
        </DashboardItemLayout>
        <DashboardItemLayout style={{ width: '50%' }} title={<Typography sx={{ m: '1rem' }} variant="h6">Current Quotes</Typography>}>
          <CarrierQuoteList />
        </DashboardItemLayout>
      </Stack>
    </PageLayout>
  );
}
