import { useShipments } from '../../../../hooks/useShipments';
import { ShipmentsFilter } from '../../../ShipmentsFilter';
import { ShipmentsList } from '../../../ShipmentsList';

interface Props {
  isFilterOpen: boolean;
  onFilterOpen: VoidFunction;
  onFilterClose: VoidFunction;
  readFromContext: boolean;
  resetReadFromContext: VoidFunction;
}

export function ShipmentListContainer(props: Props) {
  const {
    isFilterOpen, onFilterOpen, onFilterClose, readFromContext, resetReadFromContext,
  } = props;
  const { shipments } = useShipments();

  return (
    <>
      <ShipmentsFilter
        title="Available Shipments"
        isOpen={isFilterOpen}
        onOpen={onFilterOpen}
        onClose={onFilterClose}
        readFromContext={readFromContext}
        resetReadFromContext={resetReadFromContext}
      />
      <ShipmentsList shipments={shipments} />
    </>
  );
}
