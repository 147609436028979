export const createShipmentRequestBodyConstraints = {
  equipment_type: {
    presence: true,
    inclusion: {
      within: ['DRY_VAN', 'REEFER'],
      message: 'Invalid Equipment Type',
    },
  },
  service: {
    presence: true,
    inclusion: {
      within: ['TRUCK_LOAD', 'LESS_THAN_TRUCK_LOAD'],
      message: 'Invalid Service',
    },
  },
  weight_lb: {
    presence: true,
    numericality: true,
  },
  footage: {
    numericality: {
      greaterThan: 0,
    },
  },
  preferred_price: {
    presence: true,
    numericality: true,
  },
  currency: {
    presence: true,
    inclusion: {
      within: ['CAD', 'USD'],
      message: 'please select a valid currency.',
    },
  },
  commodity: {
    presence: true,
    length: {
      minimum: 1,
    },
  },
  special_requirements: {
    presence: true,
  },
};
