import {
  Chip,
} from '@mui/material';

interface Props {
  status: ShipmentStatusValue | undefined
}

export function ShipmentStatusBadge(props: Props) {
  const { status } = props;

  const getBadgeColor = (): 'primary' | 'success' | 'warning' | 'info' | 'error' => {
    switch (status) {
      case 'ACTIVE':
        return 'primary';
      case 'BOOKED':
        return 'success';
      case 'EXPIRED':
        return 'warning';
      case 'CANCELLED':
        return 'error';
      default:
        return 'info';
    }
  };

  return <Chip label={status ?? 'unknown'} color={getBadgeColor()} />;
}
