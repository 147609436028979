import { Navigate, Route, Routes } from 'react-router-dom';
import { ConfirmForgotPassword } from '../ConfirmForgotPassword';
import { ForgotPassword } from '../ForgotPassword';
import { Login } from '../Login';
import { SignUp } from '../SignUp';
import { SignUpSuccess } from '../SignUpSuccess';
import { Verify } from '../Verify';

export function Switcher() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="sign-up" element={<SignUp />} />
      <Route path="/sign-up/success" element={<SignUpSuccess />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/confirm-forgot-password" element={<ConfirmForgotPassword />} />
      <Route path="/" element={<Navigate to="/login" />} />
    </Routes>
  );
}
