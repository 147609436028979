import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../../../utils';
import { ForgotPassword } from './ForgotPassword';
import { ForgotPasswordData } from './types';

export function ForgotPasswordContainer() {
  const [form, setForm] = useState<ForgotPasswordData>({
    email: '',
  });
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const handleFormChange = (key: keyof ForgotPasswordData, value: string) => {
    setError(validateEmail(value));
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleForgotPassword = () => {
    const { email } = form;
    axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, {
      email,
    }).then(() => { navigate('/confirm-forgot-password'); });
  };

  return (
    <ForgotPassword
      onForgotPassword={handleForgotPassword}
      error={error}
      onFormChange={handleFormChange}
      disableSendPassword={form.email.length === 0 || error !== null}
    />
  );
}
