import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {
  IconButton, Tooltip, Zoom,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthContext, useRoleContext } from '../../hooks';
import { userHasCarrierRole, userHasShipperRole } from '../../utils';

interface SetSwitchRoleState {
  canSwitch: boolean;
  targetRole: Role | undefined;
}

interface SwitchRoleButtonProps {
  tooltipText: string,
  onClick: VoidFunction;
}

function SwitchRoleButton(props: SwitchRoleButtonProps) {
  const { tooltipText, onClick } = props;
  return (
    <Tooltip title={tooltipText} placement="right" TransitionComponent={Zoom}>
      <IconButton color="inherit" onClick={onClick}>
        <SyncAltIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
}

export function SwitchRoleButtonContainer() {
  const { user } = useAuthContext();
  const { role, switchToRole } = useRoleContext();
  const [canSwitchRole, setCanSwitchRole] = useState<SetSwitchRoleState>({
    canSwitch: false,
    targetRole: undefined,
  });

  const onSwitchRole = () => {
    switchToRole(canSwitchRole.targetRole as Role);
  };

  useEffect(() => {
    if (user != null && role != null) {
      if (role.type === 'SHIPPER' && userHasCarrierRole(user)) {
        setCanSwitchRole({ canSwitch: true, targetRole: user.roles.find((r) => r.type === 'CARRIER') });
      } else if (role.type === 'CARRIER' && userHasShipperRole(user)) {
        setCanSwitchRole({ canSwitch: true, targetRole: user.roles.find((r) => r.type === 'SHIPPER') });
      }
    }
  }, [user, role, setCanSwitchRole]);

  if (canSwitchRole.canSwitch && canSwitchRole?.targetRole?.type === 'SHIPPER') {
    return <SwitchRoleButton tooltipText="Switch to Shipper role" onClick={onSwitchRole} />;
  } if (canSwitchRole.canSwitch && canSwitchRole?.targetRole?.type === 'CARRIER') {
    return <SwitchRoleButton tooltipText="Switch to Carrier role" onClick={onSwitchRole} />;
  }
  return (
    <div />
  );
}
