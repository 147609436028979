import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  Divider,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { CurrencySelect } from '../../../CurrencySelect';
import { EquipmentTypeSelect } from '../../../EquipmentTypeSelect';
import { NumberInput } from '../../../NumberInput';
import { SubmitQuoteFormState } from './types';

const dividerStyle = { my: 4 };
const stackStyle = { mt: 2 };

interface Props{
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  onValueChanged: <T,>(key: keyof SubmitQuoteFormState, value: T) => void;
  toggleSubmitQuote: VoidFunction;
  enableSubmit: boolean;
  onSubmit: VoidFunction;
  error: string | null;
  loading: boolean;
  quoteSubmitted: boolean;
}

export function SubmitQuoteForm(props: Props) {
  const {
    onValueChanged, toggleSubmitQuote, enableSubmit, onSubmit, error, loading, quoteSubmitted,
  } = props;
  const onEquipmentTypeChanged = (newValue: string) => {
    onValueChanged<string>('equipmentType', newValue);
  };
  const onCurrencyChanged = (newValue: string) => {
    onValueChanged<string>('currency', newValue);
  };
  return (
    <Box mt="2rem">
      <Typography sx={{ mb: '1rem' }} variant="h6">Equipment</Typography>
      <EquipmentTypeSelect onChange={onEquipmentTypeChanged} />
      <Divider sx={dividerStyle} />
      <Typography variant="h6">Pickup time window</Typography>
      <Stack direction="row" spacing={4} sx={stackStyle}>
        <TextField
          id="datetime-local"
          label="Earliest Pickup"
          type="datetime-local"
          defaultValue=""
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => onValueChanged<string>('pickupStart', e.target.value)}
        />
        <TextField
          id="datetime-local"
          label="Latest Pickup"
          type="datetime-local"
          defaultValue=""
          sx={{ width: 250 }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => onValueChanged<string>('pickupEnd', e.target.value)}
        />
      </Stack>
      <Divider sx={dividerStyle} />
      <Typography variant="h6">Pricing</Typography>
      <Stack direction="row" spacing={4} sx={stackStyle} alignItems="center">
        <NumberInput
          inputLabel="Price"
          defaultValue={0.0}
          onChange={(val: number) => onValueChanged<number>('price', val)}
          startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
        <CurrencySelect onChange={onCurrencyChanged} />
      </Stack>
      <Divider sx={dividerStyle} />
      <Stack direction="column" spacing={1}>
        <p>Your quote will expire 30 minutes after submission.</p>
        {error != null && !loading && <Typography color="red">{error}</Typography>}
        {quoteSubmitted
        && (
          <Typography color="green">Quote submitted successfully. </Typography>
        )}
      </Stack>
      <Stack />
      <DialogActions>
        {!loading ? (
          <div>
            <Button autoFocus sx={{ mr: '1rem' }} onClick={toggleSubmitQuote}>
              Cancel
            </Button>
            <Button
              onClick={onSubmit}
              variant="contained"
              disabled={!enableSubmit}
            >
              Submit

            </Button>
          </div>
        ) : <Stack alignItems="center"><CircularProgress /></Stack>}
      </DialogActions>

    </Box>
  );
}
