import * as React from 'react';

export function useDisclosure() {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const onOpen = (): void => {
    setIsOpen(true);
  };

  const onClose = (): void => {
    setIsOpen(false);
  };

  return { isOpen, onOpen, onClose };
}
