import { ShipmentOverviewTab } from './ShipmentOverviewTab';

interface Props{
  shipment: CondensedShipment | undefined;
}

export function ShipmentOverviewTabContainer(props: Props) {
  const { shipment } = props;
  return (
    <ShipmentOverviewTab shipment={shipment} />
  );
}
