import { Button } from '@mui/material';
import { useDraggable } from 'hooks';
import { useState } from 'react';
import { areRouteTimesValid, getPlaceDetailsAsPointLocation } from '../../utils';
import { DisplayableRoutePoint } from '../RoutesForm/types';
import { RoutePoint } from './RoutePoint';

interface Props {
  routePoint: DisplayableRoutePoint;
  index: number;
  onSave: (index: number, rp: DisplayableRoutePoint) => void;
  move: (dragIndex: number, hoverIndex: number) => void;
}

export function RoutePointContainer(props: Props) {
  const {
    routePoint, index, move, onSave,
  } = props;
  const { ref, handlerId, opacity } = useDraggable<DisplayableRoutePoint>('RoutePoint', index, routePoint, move);
  const [error, setError] = useState<string | null>(null);

  const isOriginOrDest = ['ORIGIN', 'DESTINATION'].includes(
    routePoint?.stop_type ?? '',
  );

  const [isEditing, setIsEditing] = useState(isOriginOrDest
    && (!routePoint?.start_time || !routePoint?.end_time || !routePoint?.addressId));

  const [values, setValues] = useState<Partial<DisplayableRoutePoint>>({ ...routePoint });

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const handleValueChange = async <T,>(key: keyof DisplayableRoutePoint, value: T) => {
    const additional: Partial<DisplayableRoutePoint> = {};
    if (key === 'addressId') {
      const {
        pointLocation,
        addressReadable,
      } = await getPlaceDetailsAsPointLocation((value as unknown) as string);
      additional.point_location = pointLocation as RoutePointLocation;
      additional.addressReadable = addressReadable;
    }
    setValues((prev) => ({
      ...prev,
      [key]: value,
      ...additional,
    }));
  };

  const handleSave = () => {
    if (areRouteTimesValid(values.start_time, values.end_time) != null) {
      setError(areRouteTimesValid(values.start_time, values.end_time));
      return;
    }
    setError(null);
    onSave(index, values);
    setIsEditing(false);
  };

  const routePointComp = (
    <RoutePoint
      routePoint={values}
      isEditing={isEditing}
      error={error}
      onValueChange={handleValueChange}
      editButton={(
        <Button
          variant="outlined"
          onClick={() => setIsEditing(true)}
        >
          Edit
        </Button>
      )}
      onSave={handleSave}
    />
  );

  if (isOriginOrDest) {
    return <div style={{ cursor: 'not-allowed' }}>{routePointComp}</div>;
  }

  return (
    <div ref={ref} data-handler-id={handlerId} style={{ opacity, cursor: 'move' }}>{routePointComp}</div>
  );
}
