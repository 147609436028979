import { Box, Stack } from '@mui/material';
import { PageLayout } from '../../layouts';
import { QuickShipmentsBrowser } from '../QuickShipmentsBrowser';
import { DashboardBackButton } from './components/DashboardBackButton';

interface Props {
  shipmentOverview: React.ReactNode
  shipments: CondensedShipment[];
}

export function Shipment(props: Props) {
  const { shipmentOverview, shipments } = props;
  return (
    <PageLayout>
      <Stack direction="row" spacing={4}>
        <Stack width="25%" spacing={2}>
          <DashboardBackButton />
          <Box>Probably some filters</Box>
          <QuickShipmentsBrowser shipments={shipments} />
        </Stack>
        <Stack spacing={2} flex={1}>
          <Box height="20px" />
          {shipmentOverview}
        </Stack>
      </Stack>
    </PageLayout>
  );
}
