import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CommuteOutlinedIcon from '@mui/icons-material/CommuteOutlined';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import WorkOutlineTwoToneIcon from '@mui/icons-material/WorkOutlineTwoTone';
import { Stack } from '@mui/material';
import {
  capitalizeFirst, formatText, ISOStringToLocalReadableTimestamp, lbToKg,
} from '../../../../utils';
import { ShipmentDetail } from '../ShipmentDetail';

interface Props {
  shipment: CondensedShipment | undefined
}

export function ShipmentDetails(props: Props) {
  const {
    shipment,
  } = props;
  const shipmentInfo = shipment?.info;
  return (
    <Stack direction="row" spacing={8} flexWrap="wrap">
      <Stack direction="row" spacing={4}>
        <ShipmentDetail
          icon={<LocalShippingOutlinedIcon fontSize="small" />}
          title="Shipment ID"
          text={capitalizeFirst(formatText(shipmentInfo?.id ? `${shipmentInfo.id}` : '-'))}
        />
        <ShipmentDetail
          icon={<WorkOutlineTwoToneIcon fontSize="small" />}
          title="Service"
          text={capitalizeFirst(formatText(shipmentInfo?.service ?? '-'))}
        />
        <ShipmentDetail
          icon={<CommuteOutlinedIcon fontSize="small" />}
          title="Equipment Type"
          text={capitalizeFirst(formatText(shipmentInfo?.equipment_type ?? '-'))}
        />
      </Stack>
      <Stack direction="row" spacing={4}>
        <ShipmentDetail
          icon={<AttachMoneyIcon style={{ marginLeft: '-4px' }} fontSize="small" />}
          title="Preferred Price"
          text={shipmentInfo?.preferred_price != null
            ? `$ ${parseFloat(shipmentInfo.preferred_price.toString()).toFixed(2)}`
            : '-'}
        />
        <ShipmentDetail
          icon={<ScaleOutlinedIcon fontSize="small" />}
          title="Weight"
          text={shipmentInfo?.weight_lb != null
            ? `${parseFloat(shipmentInfo.weight_lb.toString()).toFixed()} lb (${lbToKg(parseFloat(shipmentInfo.weight_lb.toString())).toFixed(1)}kg)`
            : '-'}
        />
        <ShipmentDetail
          icon={<SquareFootOutlinedIcon fontSize="small" />}
          title="Required Footage"
          text={shipmentInfo?.footage != null
            ? (
              <>
                {parseFloat(shipmentInfo.footage.toString()).toFixed()}
                {' '}
                feet
              </>
            )
            : '-'}
        />
        <ShipmentDetail
          icon={<HourglassTopIcon fontSize="small" />}
          title="Expires"
          text={shipmentInfo?.expires_at != null
            ? (
              <>
                {ISOStringToLocalReadableTimestamp(shipmentInfo.expires_at)}
              </>
            )
            : '-'}
        />
      </Stack>
    </Stack>
  );
}
