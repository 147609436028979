import {
  Box, CircularProgress, Stack, Typography,
} from '@mui/material';
import { ShipmentQA } from './components/ShipmentQA';
import { SubmitQuestionMenu } from './components/SubmitQuestionMenu/SubmitQuestionMenu';

interface Props {
  error: string | null;
  loading: boolean;
  renderSubmitQuestion: boolean;
  qaList: ShipmentQA[];
  shipment: CondensedShipment | undefined
}

export function ShipmentQATab(props: Props) {
  const {
    error, loading, renderSubmitQuestion, qaList, shipment,
  } = props;

  if (loading) {
    return (
      <Stack alignItems="center" sx={{ mt: '1rem' }}>
        <CircularProgress />
      </Stack>
    );
  }
  if (error != null) {
    return (
      <Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    );
  }

  if (!qaList || qaList.length < 1) {
    return (
      <Stack>
        <Typography align="center">No questions have been asked for this shipment.</Typography>
        {renderSubmitQuestion && (
        <Box mt="2rem">
          <SubmitQuestionMenu shipmentId={shipment?.info?.id} />
        </Box>
        )}
      </Stack>
    );
  }

  return (
    <Stack direction="column" justifyContent="space-between">
      <Box>
        {qaList.map((shipmentQA) => (
          <Box key={shipmentQA.question.id}>
            <ShipmentQA shipmentQA={shipmentQA} />
          </Box>
        ))}
      </Box>
      {renderSubmitQuestion && (
      <Box mt="2rem">
        <SubmitQuestionMenu shipmentId={shipment?.info?.id} />
      </Box>
      )}
    </Stack>
  );
}
