import { useAPI } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { SimpleQuoteInteraction } from './SimpleQuoteInteraction';

interface Props {
  quoteId: number;
  onClose: VoidFunction;
  type: 'ACCEPT' | 'CANCEL';
}

export function SimpleQuoteInteractionContainer(props: Props) {
  const { quoteId, onClose, type } = props;
  const { api } = useAPI();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const getActionURL = useCallback(() => {
    if (type === 'ACCEPT') {
      return `/quotes/${quoteId}/accept`;
    }
    return `/quotes/${quoteId}/cancel`;
  }, [type, quoteId]);

  useEffect(() => {
    if (loading) {
      api<unknown>('post', getActionURL()).then(() => {
        setError(null);
        // refresh page to update data
        const currUrlQuotesFocus = `${window.location.href}?focus=quotes`;
        window.location.href = currUrlQuotesFocus;
      }).catch((e) => {
        setError(e?.response?.data?.message || `Failed to ${type.toLowerCase()} quote`);
      });
      setIsLoading(false);
    }
  }, [loading, quoteId, api, getActionURL, type]);

  const onConfirmAction = () => {
    setIsLoading(true);
  };

  return (
    <SimpleQuoteInteraction
      onClose={onClose}
      onConfirmAction={onConfirmAction}
      error={error}
      loading={loading}
      modalContentText={type === 'ACCEPT' ? 'Please confirm you wish to accept the selected quote.' : 'Please confirm you wish to cancel the selected quote.'}
      actionButtonText={type === 'ACCEPT' ? 'accept quote' : 'cancel quote'}
      title={type === 'ACCEPT' ? 'Book Shipment' : 'Cancel Quote'}
    />
  );
}
