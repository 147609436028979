import { useRoleContext } from 'hooks';
import * as React from 'react';
import { shipmentFiltersContext } from '../context';

export function useShipmentFiltersContext() {
  const shipmentFiltersCtx = React.useContext(shipmentFiltersContext);
  const { role } = useRoleContext();
  const previousRoleRef = React.useRef<null | Role>(null);

  React.useEffect(() => {
    if (previousRoleRef.current !== role) {
      previousRoleRef.current = role;
      if (role != null && role.type === 'CARRIER') {
        shipmentFiltersCtx.dispatch({ type: 'setShipmentStatus', status: 'ACTIVE' });
      } else if (role != null && role.type === 'SHIPPER') {
        shipmentFiltersCtx.dispatch({ type: 'setShipmentStatus', status: undefined });
      }
    }
  }, [role, shipmentFiltersCtx]);

  const getAllFilterValueOrValue = (value: string) => (value === 'ALL' ? '' : value);

  const updatePickupStartTime = (startTime: string) => {
    shipmentFiltersCtx.dispatch({
      type: 'setPickupWindow',
      pickupWindow: startTime,
      key: 'pickup_start',
    });
  };

  const updatePickupEndTime = (endTime: string) => {
    shipmentFiltersCtx.dispatch({
      type: 'setPickupWindow',
      pickupWindow: endTime,
      key: 'pickup_end',
    });
  };

  const updateOrigin = (
    locationKey: keyof ShipmentLocationFilter,
    locationValue: string,
  ) => {
    shipmentFiltersCtx.dispatch({
      type: 'setOrigin',
      value: locationValue,
      originKey: locationKey,
    });
  };

  const updateDestination = (locationKey: keyof ShipmentLocationFilter, locationValue: string) => {
    shipmentFiltersCtx.dispatch({
      type: 'setDestination',
      value: locationValue,
      destinationKey: locationKey,
    });
  };

  const updateOrderBy = (orderBy: string) => {
    shipmentFiltersCtx.dispatch({ type: 'setOrderBy', orderBy });
  };

  const updateEquipmentType = (equipmentType: string) => {
    const filterValue = getAllFilterValueOrValue(equipmentType);
    shipmentFiltersCtx.dispatch({ type: 'setEquipmentType', equipment: filterValue });
  };

  const updateServiceType = (serviceType: string) => {
    const filterValue = getAllFilterValueOrValue(serviceType);
    shipmentFiltersCtx.dispatch({ type: 'setService', service: filterValue });
  };

  const updateShipmentStatus = (status: ShipmentStatusValue | undefined) => {
    shipmentFiltersCtx.dispatch({ type: 'setShipmentStatus', status });
  };

  const updateCommodity = (commodity: Commodity | null) => {
    shipmentFiltersCtx.dispatch({ type: 'setCommodity', commodity });
  };

  const clearFilters = () => {
    shipmentFiltersCtx.dispatch({ type: 'clearShipmentFilters' });
  };

  const setFilters = (filters : ShipmentFilters) => {
    shipmentFiltersCtx.dispatch({ type: 'setShipmentFilters', filters });
  };

  return {
    filters: shipmentFiltersCtx.filters,
    updatePickupStartTime,
    updatePickupEndTime,
    updateOrigin,
    updateDestination,
    updateOrderBy,
    updateEquipmentType,
    updateServiceType,
    updateShipmentStatus,
    updateCommodity,
    clearFilters,
    setFilters,
  };
}
