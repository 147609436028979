import { Select } from '../Select';

interface Props {
  onChange: (value: string) => void;
  showAllOption?: boolean;
  defaultValue?: string;
  value?: string;
}

const DEFAULT_OPTIONS = [
  {
    value: 'DRY_VAN',
    label: 'Dry Van',
  },
  {
    value: 'REEFER',
    label: 'Reefer',
  },
];
const ALL_OPTION = [
  {
    value: 'ALL',
    label: 'All',
  },
];

export function EquipmentTypeSelect(props: Props) {
  const {
    onChange, showAllOption, defaultValue, value,
  } = props;
  const equipmentTypeOptions = showAllOption
    ? ALL_OPTION.concat(DEFAULT_OPTIONS)
    : DEFAULT_OPTIONS;

  return (
    <Select<string>
      inputLabel="Equipment Type"
      defaultValue={defaultValue || ''}
      value={value}
      onChange={onChange}
      options={equipmentTypeOptions}
    />
  );
}

EquipmentTypeSelect.defaultProps = {
  showAllOption: false,
  defaultValue: '',
  value: null,
};
