import * as React from 'react';

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextState {
  isAuthenticated: boolean;
  user: User | null;
  isLoading: boolean;
  isInitialized: boolean;
  auth: RefreshTokensAuthData | SignInAuthData | null;
}

interface RefreshTokenAction {
  type: 'refreshToken',
  data: {
    user: User,
    auth: RefreshTokensAuthData,
  }
}

interface RequireAuthAction {
  type: 'requireAuth';
}

interface RequestLoginAction {
  type: 'requestLogin';
}

interface LoginAction {
  type: 'login',
  data: {
    user: User,
    auth: SignInAuthData,
  }
}

interface SetUserAction {
  type: 'setUser',
  data: {
    user: User
  }
}

type AuthActions =
  LoginAction | RefreshTokenAction | RequestLoginAction | RequireAuthAction | SetUserAction;

interface AuthContextDispatch {
  dispatch: React.Dispatch<AuthActions>
}

const initalState = {
  isAuthenticated: false,
  user: null,
  isLoading: true,
  isInitialized: false,
  auth: null,
};

export const authContext = React.createContext<AuthContextState & AuthContextDispatch>({
  ...initalState,
  dispatch: () => null,
});

function reducer(state: AuthContextState, action: AuthActions): AuthContextState {
  switch (action.type) {
    case 'setUser':
      return {
        ...state,
        user: action.data.user,
      };
    case 'requestLogin':
      return {
        ...state,
        isLoading: true,
      };
    case 'login':
      return {
        isAuthenticated: true,
        user: action.data.user,
        auth: action.data.auth,
        isLoading: false,
        isInitialized: true,
      };
    case 'refreshToken':
      return {
        isAuthenticated: true,
        user: action.data.user,
        auth: action.data.auth,
        isLoading: false,
        isInitialized: true,
      };
    case 'requireAuth':
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export function AuthProvider(props: AuthProviderProps) {
  const { children } = props;

  const [state, dispatch] = React.useReducer(reducer, initalState);

  const memoState = React.useMemo(() => ({ ...state, dispatch }), [state]);

  return (
    <authContext.Provider value={memoState}>
      {children}
    </authContext.Provider>
  );
}
