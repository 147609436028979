import { useDisclosure } from 'hooks';
import { useShipments } from '../../../../hooks/useShipments';
import { ShipmentsFilter } from '../../../ShipmentsFilter';
import { ShipmentsList } from '../../../ShipmentsList';

export function ShipmentListContainer() {
  const { shipments } = useShipments();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ShipmentsFilter title="Shipments" isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <ShipmentsList shipments={shipments} />
    </>
  );
}
