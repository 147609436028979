import { useDisclosure } from 'hooks';
import { useCallback, useEffect, useState } from 'react';
import { QuoteInteraction } from './QuoteInteraction';
import { DEFAULT_QUOTE_STATE, QuoteActionsState } from './types';

interface Props {
  quoteId: number;
  actions: Array<string>;
  viewQuoteHistory: React.ReactNode;
}

export function QuoteInteractionContainer(props: Props) {
  const {
    actions,
    viewQuoteHistory,
    quoteId,
  } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [quoteActionsState,
    setQuoteActionState] = useState<QuoteActionsState>(DEFAULT_QUOTE_STATE);
  const [modalAction, setModalAction] = useState<CondensedQuoteActionValue | null>(null);

  const onActionClick = useCallback((actionType: CondensedQuoteActionValue) => {
    setModalAction(actionType);
    onOpen();
  }, [onOpen]);

  const setupActionState = useCallback((
    actionType: keyof QuoteActionsState,
  ) => {
    setQuoteActionState((prev) => ({
      ...prev,
      [actionType]: {
        actionType,
        isDisplayed: actions.includes(actionType),
      },
    }));
  }, [actions]);

  useEffect(() => {
    actions.forEach((action) => {
      if (action === 'ACCEPT') {
        setupActionState(action);
      } else if (action === 'COUNTER') {
        setupActionState(action);
      } else if (action === 'CANCEL') {
        setupActionState(action);
      }
    });
  }, [actions, setupActionState]);

  return (
    <QuoteInteraction
      viewQuoteHistory={viewQuoteHistory}
      acceptQuote={quoteActionsState.ACCEPT}
      counterQuote={quoteActionsState.COUNTER}
      cancelQuote={quoteActionsState.CANCEL}
      quoteId={quoteId}
      modalOpen={isOpen}
      onCloseModal={onClose}
      onActionClicked={onActionClick}
      modalAction={modalAction}
    />
  );
}
