import qs from 'qs';
import { useCallback, useEffect, useState } from 'react';
import { naiveTimestampToISOString } from '../utils/index';
import { useAPI } from './useAPI';
import { useAuthContext } from './useAuthContext';
import { useShipmentFiltersContext } from './useShipmentFiltersContext';
import { useShipmentsUrl } from './useShipmentsUrl';

interface GetCondensedShipmentsResponse {
  results: CondensedShipment[];
}

interface ShipmentsState {
  data: CondensedShipment[],
  isLoading: boolean;
}

const buildFilterQueryParameters = (filters: ShipmentFilters | null) => {
  const pickupStart = filters?.pickup_window?.pickup_start && filters.pickup_window.pickup_start !== ''
    ? naiveTimestampToISOString(filters.pickup_window.pickup_start)
    : null;
  const pickupEnd = filters?.pickup_window?.pickup_end && filters.pickup_window.pickup_end !== ''
    ? naiveTimestampToISOString(filters.pickup_window.pickup_end)
    : null;
  if (filters) {
    const filtersObject = {
      equipment_type: filters.equipment_type || null,
      service: filters.service || null,
      commodity: filters.commodity?.code || null,
      status: filters.status || null,
      order_by: filters.order_by || null,
      origin_city: filters?.origin?.city || null,
      origin_province: filters?.origin?.province || null,
      origin_country: filters?.origin?.country || null,
      destination_city: filters?.destination?.city || null,
      destination_province: filters?.destination?.province || null,
      destination_country: filters?.destination?.country || null,
      pickup_window: {
        pickup_start: pickupStart,
        pickup_end: pickupEnd,
      },
    };

    return qs.stringify(filtersObject, { skipNulls: true });
  }
  return '';
};

export function useShipments() {
  const { user } = useAuthContext();
  const { api } = useAPI();
  const { url } = useShipmentsUrl();
  const { filters } = useShipmentFiltersContext();

  const [shipments, setShipments] = useState<ShipmentsState>({
    data: [],
    isLoading: true,
  });

  const fetchShipments = useCallback(() => {
    const filtersQueryString = buildFilterQueryParameters(filters);
    const filteredUrl = `${url}?${filtersQueryString}`;
    if (user != null && url != null) {
      api<GetCondensedShipmentsResponse>('get', filteredUrl).then((res) => {
        setShipments({ data: res.data.results, isLoading: false });
      }).catch(() => setShipments({ data: [], isLoading: false }));
    }
  }, [user, api, url, filters]);

  useEffect(() => {
    if (user?.id != null) {
      fetchShipments();
    }
  }, [user, filters, fetchShipments]);

  return { shipments: shipments.data, isLoading: shipments.isLoading };
}
