import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
  Box, Button, Divider, Stack, TextField, Typography,
} from '@mui/material';
import { CommoditySelectInput } from 'components/CommoditySelectInput';
import { EquipmentTypeSelect } from 'components/EquipmentTypeSelect';
import { Select } from 'components/Select';
import { ServiceTypeSelect } from 'components/ServiceTypeSelect';
import { ShipmentStatusSelect } from 'components/ShipmentStatusSelect';
import { TimeInput } from 'components/TimeInput';
import { ShipmentSaveSearch } from '../ShipmentSaveSearch';
import {
  DateWindow, Location, ShipmentsFilterDisplay, ShipmentsFilterState,
} from './types';

const boxWrapperStyle = { mr: '1rem', mt: '1rem' };

interface Props {
  title?: string;
  filters: ShipmentsFilterState;
  displayedFilters: ShipmentsFilterDisplay;
  filterToolsAreVisible: boolean;
  toggleFilterTools: (value: boolean) => void;
  onLocationChanged: (
    key: keyof Location,
    addressKey: keyof ShipmentLocationFilter,
    value: string
  ) => void;
  onDateWindowChanged: (key: keyof DateWindow, value: string) => void;
  onOrderChanged: (key: keyof ShipmentsFilterState, value: string) => void;
  onEquipmentTypeChanged: (value: string) => void;
  onServiceTypeChanged: (value: string) => void;
  onShipmentStatusChanged: (value: string) => void;
  onCommodityChanged: (value: Commodity | null) => void;
}

export function ShipmentsFilter(props: Props) {
  const {
    title,
    filters,
    displayedFilters,
    filterToolsAreVisible,
    toggleFilterTools,
    onLocationChanged,
    onDateWindowChanged,
    onOrderChanged,
    onEquipmentTypeChanged,
    onServiceTypeChanged,
    onShipmentStatusChanged,
    onCommodityChanged,
  } = props;

  const onOriginChanged = (key: keyof ShipmentLocationFilter, newValue: string) => {
    onLocationChanged('origin', key, newValue);
  };

  const onDestinationChanged = (key: keyof ShipmentLocationFilter, newValue: string) => {
    onLocationChanged('destination', key, newValue);
  };

  return (
    <Box margin={2}>
      <Box mb={2}>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
          <Typography variant="h6">{title}</Typography>
          <Button
            variant="contained"
            onClick={() => toggleFilterTools(!filterToolsAreVisible)}
          >
            {filterToolsAreVisible ? 'Hide filters' : 'Show filters'}
          </Button>
        </Stack>
      </Box>
      {(filterToolsAreVisible) && (
      <Box mt={1}>
        <Stack direction="row" flexWrap="wrap" mb={1} alignItems="flex-end">
          <Box sx={boxWrapperStyle}>
            <EquipmentTypeSelect
              defaultValue={filters.equipmentType}
              value={filters.equipmentType}
              onChange={(newValue) => onEquipmentTypeChanged(newValue)}
              showAllOption
            />
          </Box>
          <Box sx={boxWrapperStyle}>
            <ServiceTypeSelect
              defaultValue={filters.service}
              value={filters.service}
              onChange={(newValue) => onServiceTypeChanged(newValue)}
              showAllOption
            />
          </Box>
          <Box sx={boxWrapperStyle}>
            <CommoditySelectInput
              commodityValue={filters.commodity}
              onCommoditySelected={onCommodityChanged}
              textFieldProps={{ label: 'Commodity' }}
            />
          </Box>

        </Stack>
        <Stack direction="row" flexWrap="wrap" mb={1} alignItems="flex-end">
          <Box sx={boxWrapperStyle}>
            <TextField
              value={filters.origin.city}
              onChange={(event) => onOriginChanged('city', event.target.value)}
              label="Origin city"
            />
          </Box>
          <Box sx={boxWrapperStyle}>
            <TextField
              value={filters.origin.province}
              onChange={(event) => onOriginChanged('province', event.target.value)}
              label="Origin state/province"
            />
          </Box>
          <Box sx={boxWrapperStyle}>
            <TextField
              value={filters.origin.country}
              onChange={(event) => onOriginChanged('country', event.target.value)}
              label="Origin country"
            />
          </Box>
        </Stack>
        <Stack direction="row" flexWrap="wrap" mb={1} alignItems="flex-end">
          <Box sx={boxWrapperStyle}>
            <TextField
              value={filters.destination.city}
              onChange={(event) => onDestinationChanged('city', event.target.value)}
              label="Destination city"
            />
          </Box>
          <Box sx={boxWrapperStyle}>
            <TextField
              value={filters.destination.province}
              onChange={(event) => onDestinationChanged('province', event.target.value)}
              label="Destination state/province"
            />
          </Box>
          <Box sx={boxWrapperStyle}>
            <TextField
              value={filters.destination.country}
              onChange={(event) => onDestinationChanged('country', event.target.value)}
              label="Destination country"
            />
          </Box>
        </Stack>
        <Stack direction="row" flexWrap="wrap" mb={1} alignItems="flex-end">
          <Box sx={boxWrapperStyle}>
            <TimeInput
              label="Earliest pickup time"
              initialValue=""
              onChange={(newValue) => onDateWindowChanged('pickupStartTime', newValue)}
            />
          </Box>
          <Box sx={boxWrapperStyle}>
            <TimeInput
              label="Latest pickup time"
              initialValue=""
              onChange={(newValue) => onDateWindowChanged('pickupEndTime', newValue)}
            />
          </Box>
        </Stack>
        {(displayedFilters.saveSearch) && (
          <>
            <Stack direction="column" flexWrap="wrap" mb={1} alignItems="flex-start">
              <Box sx={boxWrapperStyle}>
                <ShipmentSaveSearch />
              </Box>
            </Stack>
            <Divider sx={{ borderBottomWidth: 2 }} />
          </>
        )}
        <Stack direction="row" flexWrap="wrap" mb={2} alignItems="flex-end">
          {(displayedFilters.shipmentStatus) && (
            <Box sx={boxWrapperStyle}>
              <ShipmentStatusSelect
                defaultValue={filters.shipmentStatus}
                onChange={(newValue) => onShipmentStatusChanged(newValue)}
                showAllOption
              />
            </Box>
          )}
          <Box sx={boxWrapperStyle}>
            <Stack direction="row" flexWrap="wrap" alignItems="flex-end" spacing={1}>
              <Box>
                <Select<string>
                  inputLabel="Sort by"
                  defaultValue={filters.orderBy}
                  onChange={(newValue) => onOrderChanged('orderBy', newValue)}
                  options={[
                    {
                      value: 'created_at',
                      label: 'Posting date',
                    },
                  ]}
                />
              </Box>
              <Box>
                <Button
                  onClick={() => {
                    if (filters.sortOrder === 'ASC') {
                      onOrderChanged('sortOrder', 'DESC');
                    }
                    if (filters.sortOrder === 'DESC') {
                      onOrderChanged('sortOrder', 'ASC');
                    }
                  }}
                  startIcon={filters.sortOrder === 'ASC' ? <ArrowUpward /> : <ArrowDownward />}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Box>
      )}
    </Box>
  );
}

ShipmentsFilter.defaultProps = {
  title: 'Filters',
};
