import {
  Box,
} from '@mui/material';
import { Select } from '../Select';
import countries from './countries';

interface Props {
  country: string;
  onChange: (value: string) => void;
  label?: string;
}

export function CountrySelect(props: Props) {
  const { country, label, onChange } = props;

  return (
    <Box width="100%">
      <Select<string>
        inputLabel={label ?? 'Country'}
        defaultValue=""
        value={country}
        onChange={(e) => onChange(e)}
        options={countries}
      />
    </Box>
  );
}

CountrySelect.defaultProps = {
  label: undefined,
};
