import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { CurrencySelect } from '../../../../../CurrencySelect';
import { NumberInput } from '../../../../../NumberInput';
import { CounterQuoteFormState } from './types';

interface Props {
  onClose: VoidFunction;
  onConfirmAction: VoidFunction;
  error: string | null;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  onInputChange: <T>(key: keyof CounterQuoteFormState, value: T) => void;
  disableSubmit: boolean;
}

export function CounterQuoteInteraction(props: Props) {
  const {
    onClose, onConfirmAction, loading, error, onInputChange, disableSubmit,
  } = props;
  const onCurrencyChanged = (newValue: string) => {
    onInputChange<string>('currency', newValue);
  };
  return (

    <Dialog open fullWidth onClose={onClose}>
      <DialogTitle>
        Counter Quote
      </DialogTitle>
      <DialogContent>
        {loading
          ? <Stack alignItems="center"><CircularProgress /></Stack>
          : (
            <Stack direction="row" spacing={1} m="1rem">
              <NumberInput
                inputLabel="Price"
                defaultValue={0.0}
                onChange={(val: number) => onInputChange<number>('price', val)}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
              />
              <CurrencySelect onChange={onCurrencyChanged} />
            </Stack>
          )}
        {error != null && !loading && <Typography color="red" align="center">{error}</Typography>}
      </DialogContent>
      {!loading && (
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={disableSubmit} onClick={onConfirmAction}>Submit counter quote</Button>
      </DialogActions>
      )}
    </Dialog>
  );
}
