import { useAPI, useRoleContext } from 'hooks';
import { useEffect, useState } from 'react';
import { CarrierQuoteList } from './CarrierQuoteList';

export function CarrierQuoteListContainer() {
  const [quotes, setQuotes] = useState<CondensedQuote[]>([]);
  const [loading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<null | string>(null);
  const { api } = useAPI();
  const { role } = useRoleContext();

  useEffect(() => {
    if (loading && role != null) {
      api<CondensedQuote[]>('get', `/carriers/${role.id}/quotes/condensed`).then((response) => {
        setQuotes(response.data);
        setError(null);
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to fetch quotes');
      });
      setIsLoading(false);
    }
  }, [loading, role, api]);

  if (role == null) {
    return <div />;
  }

  return (
    <CarrierQuoteList
      quotes={quotes}
      loading={loading}
      error={error}
    />
  );
}
