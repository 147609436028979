import { DateTime } from 'luxon';
import { QUOTE_STATUS_PRIORITY_LIST } from '../constants';

export function capitalizeFirst(words: string): string {
  return words
    .toLowerCase()
    .split(' ')
    .map((word) => (word[0] ?? '').toUpperCase() + word.substring(1))
    .join(' ');
}

export function formatText(text: string): string {
  return text.toLowerCase().split('_').join(' ');
}

export function userBelongsToCompany(user: User): boolean {
  return user?.roles.some((role) => role?.company != null);
}

export function validateEmail(email: string): string | null {
  const emailRegEx = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/g;
  return emailRegEx.test(email) ? null : 'Invalid email format';
}

export function getRouteOrigin(routePoints: RoutePoint[]): RoutePoint | null {
  let point = null;
  routePoints.forEach((rp) => {
    if (rp.stop_type === 'ORIGIN') {
      point = rp;
    }
  });
  return point;
}

export function getRouteDestination(routePoints: RoutePoint[]): RoutePoint | null {
  let point = null;
  routePoints.forEach((rp) => {
    if (rp.stop_type === 'DESTINATION') {
      point = rp;
    }
  });
  return point;
}

export function userHasShipperRole(user: User): boolean {
  return user.roles.some((role: Role) => role.type === 'SHIPPER');
}

export function userHasCarrierRole(user: User): boolean {
  return user.roles.some((role: Role) => role.type === 'CARRIER');
}

export function lbToKg(number: number): number {
  return number * 0.45359237;
}

export function naiveTimestampToISOString(naiveLocalTimestamp: string): string {
  const date = new Date(naiveLocalTimestamp);
  return date.toISOString(); // ISO standard string is UTC
}

export function startLessOrEqualToEnd(
  naiveLocalTimestampStart: string,
  naiveLocalTimestampEnd: string,
): boolean {
  // compares the two given naive local timestamps and returns wether start <= end
  const start = new Date(naiveLocalTimestampStart);
  const end = new Date(naiveLocalTimestampEnd);
  return start <= end;
}

export function ISOStringToLocalReadableTimestamp(ISOTimestamp: string): string {
  // Reference: https://moment.github.io/luxon/api-docs/index.html#datetimetolocalestring
  return DateTime.fromISO(ISOTimestamp).toLocaleString({
    weekday: 'long', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', year: 'numeric',
  });
}

export function mcNumberIsValid(mcNumber: string): string | null {
  if (mcNumber.length < 2 || mcNumber.length > 10) {
    return 'MC number must be between 2 and 10 numerical digits.';
  }
  return null;
}

export function getReadableRoutePointLocation(routePoint: RoutePoint): string {
  if (routePoint.point_location) {
    const location = routePoint.point_location;
    return `${location.address ? `${location.address},` : ''} 
    ${location.city ? `${location.city},` : ''} 
    ${location.province ? `${location.province},` : ''}
    ${location.country ? `${location.country}` : ''}
    ${location.postal ? `${location.postal}` : ''}
    `;
  }
  return '';
}

export function orderQuotesByQuoteStatus(condensed_quotes: CondensedQuote[]): CondensedQuote[] {
  return condensed_quotes.sort((a, b) => QUOTE_STATUS_PRIORITY_LIST.indexOf(a.state)
  - QUOTE_STATUS_PRIORITY_LIST.indexOf(b.state));
}
