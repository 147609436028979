import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

interface Props {
  saveSearchModalIsOpen: boolean;
  searchName: string;
  onCloseModal: () => void;
  onOpenModal: () => void;
  handleChangeSearchName: (name: string) => void;
  onSubmitSaveSearch: (name: string) => void;
  submitSaveSearchIsLoading: boolean;
  submitSaveSearchError: string | null;
}

export function ShipmentSaveSearch(props: Props) {
  const {
    saveSearchModalIsOpen,
    searchName,
    onCloseModal,
    onOpenModal,
    handleChangeSearchName,
    onSubmitSaveSearch,
    submitSaveSearchIsLoading,
    submitSaveSearchError,
  } = props;

  return (
    <>
      <Stack direction="column" flexWrap="wrap" mb={1} alignItems="flex-start">
        <Button
          variant="contained"
          onClick={onOpenModal}
        >
          Save search
        </Button>
        <Box sx={{ mt: '0.5rem' }}>
          <Typography variant="body2" color="text.secondary">
            Save these filters to be alerted
            {' '}
            of shipments meeting the parameters in the future.
          </Typography>
        </Box>
      </Stack>
      <Dialog
        open={saveSearchModalIsOpen}
        onClose={onCloseModal}
        aria-labelledby="alert-save-search-title"
      >
        <DialogTitle id="alert-save-search-title">Save your search</DialogTitle>
        <DialogContent>
          {submitSaveSearchIsLoading
            ? <Stack alignItems="center"><CircularProgress /></Stack>
            : (
              <>
                <DialogContentText id="alert-save-search-description">
                  Enter a name to identify your saved shipment search.
                </DialogContentText>
                <Stack direction="row" spacing={1} m="1rem">
                  <TextField
                    id="save-search-input"
                    label="Name"
                    variant="outlined"
                    value={searchName}
                    required
                    fullWidth
                    onChange={(e) => handleChangeSearchName(e.target.value)}
                  />
                </Stack>
                <Stack direction="row" spacing={1} ml="1rem" mr="1rem">
                  {submitSaveSearchError != null && <Typography color="red">{submitSaveSearchError}</Typography>}
                </Stack>
              </>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseModal}>Cancel</Button>
          <Button
            onClick={() => { onSubmitSaveSearch(searchName); }}
            autoFocus
            disabled={searchName.length < 1}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
