import { useMemo, useState } from 'react';
import { useRoleContext } from './useRoleContext';

export function useShipmentsUrl() {
  const [url, setUrl] = useState<string | null>(null);
  const { role } = useRoleContext();
  useMemo(() => {
    if (role != null) {
      if (role.type === 'SHIPPER') {
        setUrl(`/shippers/${role.id}/shipments/condensed`);
      } else if (role.type === 'CARRIER') {
        setUrl('/shipments/condensed');
      }
    }
  }, [role]);
  return { url };
}
