import {
  Box, Button, Divider, Stack, TextField, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PasswordToggableIconEndAdornment } from '../PasswordToggableIconEndAdornment';
import { LoginData } from './types';

interface Props {
  onLogin: VoidFunction;
  onFormChange: (key: keyof LoginData, value: string) => void;
  onShowPasswordChange: () => void;
  showPassword: boolean;
  loginError: null | string;
}

export function Login(props: Props) {
  const {
    onLogin, onFormChange, onShowPasswordChange, showPassword, loginError,
  } = props;
  const navigate = useNavigate();

  const loginOnEnter = (e: any) => {
    if (e.key === 'Enter') {
      onLogin();
    }
  };

  return (
    <Box>
      <Typography textAlign="center" variant="h4" fontWeight="bold">
        Login to Evotrux
      </Typography>
      <Stack my="2rem" spacing={2}>
        <TextField
          data-testid="email-input"
          id="email-input"
          label="Email"
          variant="outlined"
          onChange={(e) => onFormChange('email', e.target.value)}
          onKeyPress={loginOnEnter}
        />
        <TextField
          data-testid="password-input"
          id="password-input"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          variant="outlined"
          onChange={(e) => onFormChange('password', e.target.value)}
          InputProps={{
            endAdornment: (<PasswordToggableIconEndAdornment
              showPassword={showPassword}
              onIconClick={onShowPasswordChange}
            />
            ),
          }}
          onKeyPress={loginOnEnter}
        />
        {loginError !== null && <Typography color="red" align="center">{loginError}</Typography>}
        <Button variant="contained" onClick={onLogin}>Login</Button>
        <Divider variant="middle" />
        <Button variant="text" onClick={() => navigate('/forgot-password')}>Forgot Password?</Button>
        <Button onClick={() => navigate('/sign-up')}>Create account</Button>
      </Stack>
    </Box>
  );
}
