import { useAPI, useDisclosure, useRouteDistance } from 'hooks';
import { useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { validate } from 'validate.js';
import { naiveTimestampToISOString } from '../../../../utils';
import { RoutePoint } from './components/RoutePoint';
import { RoutesForm } from './components/RoutesForm';
import { DisplayableRoutePoint } from './components/RoutesForm/types';
import { RoutesFormModal } from './components/RoutesFormModal';
import { createShipmentRequestBodyConstraints } from './constraints';
import { CreateShipmentForm } from './CreateShipmentForm';
import { CreateShipmentFormState } from './types';

interface CreateShipmentRequestBody {
  point: RoutePoint;
  point_location: RoutePointLocation;
}

const initialState: CreateShipmentFormState = {
  equipmentType: '',
  service: '',
  commodity: '',
  weightLb: '',
  footage: '',
  specialRequirements: 'default', // TODO: Allow empty string
  additionalDetails: 'default',
  preferredPrice: '',
  routePoints: [
    { stop_type: 'ORIGIN', stop_operation: 'PICKUP', point_number: 1 },
    { stop_type: 'DESTINATION', stop_operation: 'DROPOFF', point_number: 2 },
  ],
  currency: '',
};

export function CreateShipmentFormContainer() {
  const [formState, setFormState] = useState<CreateShipmentFormState>(initialState);
  const [commodity, setCommodity] = useState<Commodity | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [error, setError] = useState<string | null>(null);
  const { api } = useAPI();

  // casting because hook should not care about addition human readable fields
  const routeDistance = useRouteDistance(formState.routePoints as RoutePoint[]);

  const handleOpen = () => {
    onOpen();
  };

  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const handleValueChange = <T,>(key: keyof CreateShipmentFormState, value: T | null): void => {
    // validation here
    if (key === 'routePoints') {
      setFormState((prev) => {
        const rps = [...prev.routePoints];
        rps.splice(
          prev.routePoints.length - 1,
          0,
          (value as unknown) as DisplayableRoutePoint,
        );
        return {
          ...prev,
          routePoints: rps,
        };
      });
      onClose();
    } else if (key === 'commodity') {
      const commodityValue = value as unknown as Commodity;
      setFormState((prev) => ({
        ...prev,
        [key]: commodityValue?.code,
      }));
      setCommodity(commodityValue);
    } else {
      setFormState((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  };

  const handleRoutePointSave = (index: number, rp: DisplayableRoutePoint) => {
    setFormState((prev) => {
      const rps = [...prev.routePoints];
      rps.splice(index, 1, rp);
      return {
        ...prev,
        routePoints: rps,
      };
    });
  };

  const routePointsAreValid = () => {
    const { routePoints } = formState;

    if (routePoints.length < 2) {
      setError('Please add at least 2 route points to your shipment.');
      return false;
    }

    return routePoints.every((rp, i) => {
      if (!rp.start_time) {
        setError(`Route point ${i + 1} missing start time.`);
        return false;
      }
      if (!rp.end_time) {
        setError(`Route point ${i + 1} missing end time.`);
        return false;
      }
      return true;
    });
  };

  const buildRequestBodyRoutePoints = () => {
    const { routePoints } = formState;

    const bodyRps: CreateShipmentRequestBody[] = [];
    routePoints.forEach((rp, i) => {
      const { point_location: pointLocation, ...rest } = rp;
      const route = { ...rest } as RoutePoint;
      bodyRps.push({
        point: {
          ...route,
          point_number: i + 1,
          start_time: naiveTimestampToISOString(route.start_time),
          end_time: naiveTimestampToISOString(route.end_time),
        },
        point_location: pointLocation as RoutePointLocation,
      });
    });

    return bodyRps;
  };

  const handleSubmitShipment = () => {
    const shipment = {
      equipment_type: formState.equipmentType,
      service: formState.service,
      weight_lb: formState.weightLb,
      footage: formState.footage === '' ? null : formState.footage,
      preferred_price: formState.preferredPrice,
      currency: formState.currency,
      commodity: formState.commodity,
      special_requirements: formState.specialRequirements,
    };
    if (!routePointsAreValid()) {
      return;
    }
    const route = buildRequestBodyRoutePoints();
    const shipmentBodyErrors = validate(shipment, createShipmentRequestBodyConstraints);

    if (!shipmentBodyErrors) {
      api('post', '/shipments', { shipment, route }).then(() => {
        setError(null);
        window.location.href = '/';
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to create shipment. Please contact us if the problem persists.');
      });
    } else {
      // display the first validation error message.
      /*
      Sample structure of validation errors:
      {
        "equipment_type": [
            "Equipment type Invalid Equipment Type"
        ],
      }
      */
      const errorKeys = Object.keys(shipmentBodyErrors);
      if (errorKeys.length > 0 && errorKeys[0].length > 0) {
        setError(shipmentBodyErrors[errorKeys[0]][0]);
      }
    }
  };

  const moveRoutePoint = useCallback((dragIndex: number, hoverIndex: number) => {
    setFormState((prev) => {
      if (hoverIndex === 0 || hoverIndex === prev.routePoints.length - 1) {
        return prev;
      }
      const prevPoints = prev.routePoints;
      const originalItem = prev.routePoints[dragIndex];
      prevPoints.splice(dragIndex, 1);
      prevPoints.splice(hoverIndex, 0, originalItem);
      return {
        ...prev,
        routePoints: prevPoints,
      };
    });
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <CreateShipmentForm
        routePoints={formState.routePoints.map((rp, i) => (
          <RoutePoint
            key={`${rp.start_time}-${i.toString()}`}
            index={i}
            routePoint={rp as DisplayableRoutePoint}
            onSave={handleRoutePointSave}
            move={moveRoutePoint}
          />
        ))}
        commodity={commodity}
        routeDistance={routeDistance}
        serviceType={formState.service}
        error={error}
        onValueChanged={handleValueChange}
        toggleCreateRouteOpen={handleOpen}
        onSubmit={handleSubmitShipment}

      />
      <RoutesFormModal
        open={isOpen}
        onClose={onClose}
        form={<RoutesForm onSubmit={(value) => handleValueChange<DisplayableRoutePoint>('routePoints', value)} />}

      />
    </DndProvider>
  );
}
