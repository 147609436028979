import { ShipmentsFilterDisplay, ShipmentsFilterState } from './types';

export const INITIAL_FILTER_STATE: ShipmentsFilterState = {
  equipmentType: 'ALL',
  service: 'ALL',
  commodity: null,
  shipmentStatus: 'ALL',
  origin: { city: '', province: '', country: '' },
  destination: { city: '', province: '', country: '' },
  pickupStartTime: '',
  pickupEndTime: '',
  orderBy: 'created_at',
  sortOrder: 'DESC',
};

export const SHIPPER_FILTER_DISPLAY_VALUES: ShipmentsFilterDisplay = {
  equipmentType: true,
  service: true,
  commodity: true,
  shipmentStatus: true,
  origin: true,
  destination: true,
  pickupStartTime: true,
  pickupEndTime: true,
  orderBy: true,
  sortOrder: true,
  saveSearch: false,
};

export const CARRIER_FILTER_DISPLAY_VALUES: ShipmentsFilterDisplay = {
  equipmentType: true,
  service: true,
  commodity: true,
  shipmentStatus: false,
  origin: true,
  destination: true,
  pickupStartTime: true,
  pickupEndTime: true,
  orderBy: true,
  sortOrder: true,
  saveSearch: true,
};
