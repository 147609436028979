import axios, { AxiosRequestConfig } from 'axios';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { authContext } from '../context';

export function useAuthContext() {
  const userCtx = React.useContext(authContext);
  const navigate = useNavigate();

  const signout = React.useCallback(() => {
    userCtx.dispatch({ type: 'requireAuth' });
    navigate('/');
    localStorage.clear();
    window.location.reload();
  }, [navigate, userCtx]);

  const refreshTokens = React.useCallback((refreshToken: string, user: User) => axios.patch(
    `${process.env.REACT_APP_API_URL}/tokens/refresh`,
    {
      email: user.email,
      refresh_token: refreshToken,
    },
  ).then((res) => {
    const { data: { data } } = res;
    userCtx.dispatch({ type: 'refreshToken', data: { auth: data.auth, user } });
    navigate(window.location.pathname === '/login' ? '/' : window.location.pathname);
  }).catch(() => {
    // refresh token has likely expired
    signout();
  }), [userCtx, navigate, signout]);

  function getLocalAuth() {
    return localStorage.getItem('auth');
  }

  function getLocalUser() {
    return localStorage.getItem('user');
  }

  function getLocalRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  const refresh = React.useCallback(() => {
    const localUser = getLocalUser();
    const localRefreshToken = getLocalRefreshToken();
    if (localUser != null && localRefreshToken != null) {
      refreshTokens(localRefreshToken, JSON.parse(localUser));
    } else {
      signout();
    }
  }, [refreshTokens, signout]);

  async function login(email: string, password: string) {
    userCtx.dispatch({ type: 'requestLogin' });
    return axios.post(`${process.env.REACT_APP_API_URL}/login`, {
      email,
      password,
    }).then((res) => {
      const { data: { data } } = res;
      userCtx.dispatch({ type: 'login', data: { user: data.user, auth: data.auth } });
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('auth', JSON.stringify(data.auth));
      localStorage.setItem('refreshToken', data.auth.AuthenticationResult.RefreshToken);
      navigate(window.location.pathname === '/login' ? '/' : window.location.pathname);
    });
  }

  const refreshUser = React.useCallback(async () => {
    const { auth, dispatch } = userCtx;
    let idToken = null;
    if (auth != null) {
      idToken = auth.AuthenticationResult.IdToken;
      const config: AxiosRequestConfig = {
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/user`,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };
      return axios.request(config).then((res) => {
        localStorage.setItem('user', JSON.stringify(res.data));
        dispatch({ type: 'setUser', data: { user: res.data } });
      }).catch(() => dispatch({ type: 'requireAuth' }));
    }
    return dispatch({ type: 'requireAuth' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCtx.auth, userCtx.dispatch]);

  async function signup(user: Partial<User>, password: string, role: RoleValue) {
    const { email, first_name: firstName, last_name: lastName } = user;
    return axios.post(`${process.env.REACT_APP_API_URL}/signup`, {
      email,
      password,
      first_name: firstName,
      last_name: lastName,
      role,
    }).then((res) => {
      const { data: { data } } = res;
      userCtx.dispatch({ type: 'setUser', data: { user: data.user } });
    });
  }

  React.useEffect(() => {
    const localAuth = getLocalAuth();
    const localUser = getLocalUser();
    const localRefreshToken = getLocalRefreshToken();

    if (
      userCtx.auth == null
      && userCtx.user == null
      && localAuth != null
      && localUser != null
      && !userCtx.isInitialized
      && localRefreshToken != null
    ) {
      refreshTokens(localRefreshToken, JSON.parse(localUser));
    } else
    if ((localAuth == null || localUser == null || localRefreshToken == null)
    && userCtx.isLoading) {
      userCtx.dispatch({ type: 'requireAuth' });
    }
  }, [userCtx, refreshTokens]);

  let idToken = null;
  if (userCtx.auth != null) {
    idToken = userCtx.auth.AuthenticationResult.IdToken;
  }

  return {
    user: userCtx.user,
    isAuthenticated: userCtx.isAuthenticated,
    isLoading: userCtx.isLoading,
    login,
    signup,
    signout,
    refresh,
    refreshUser,
    idToken,
  };
}
