import { CircularProgress, Stack, Typography } from '@mui/material';
import { ShipmentFiltersProvider } from 'context';
import { useAuthContext } from '../../../../hooks';
import { DashboardItemLayout, PageLayout } from '../../../../layouts';
import { CreateShipmentForm } from '../CreateShipmentForm';
import { ShipmentListContainer } from '../ShipmentListContainer';

export function Dashboard() {
  const { user } = useAuthContext();
  if (!user) {
    <Stack>
      <CircularProgress />
    </Stack>;
  }
  return (
    <PageLayout>
      <Typography variant="h4" fontWeight={600} mb="2rem">Shipments</Typography>
      <Stack direction="row" spacing={8}>
        <DashboardItemLayout style={{ p: '2rem' }} title={<Typography variant="h6">Post a New Shipment</Typography>}>
          <CreateShipmentForm />
        </DashboardItemLayout>
        <DashboardItemLayout style={{ width: '50%' }}>
          <ShipmentFiltersProvider>
            <ShipmentListContainer />
          </ShipmentFiltersProvider>
        </DashboardItemLayout>
      </Stack>
    </PageLayout>
  );
}
