import { Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ISOStringToLocalReadableTimestamp } from '../../../../utils';

interface Props {
  error: string | null;
  actions: QuoteAction[];
}

interface QuoteActionProps {
  action: QuoteAction;
}

function QuoteAction(props: QuoteActionProps) {
  const { action } = props;
  const eventText = action.action === 'ACTIVE' ? 'Quote submitted' : `Quote ${action.action.toLowerCase()}`;
  return (
    <TableRow>
      <TableCell>{eventText}</TableCell>
      <TableCell>
        {action?.currency?.code && action?.price ? `$${action.price
        } ${action.currency.code}` : '-'}

      </TableCell>
      <TableCell>{action?.created_at ? ISOStringToLocalReadableTimestamp(action?.created_at) : '-'}</TableCell>
      <TableCell>{action?.expires_at ? ISOStringToLocalReadableTimestamp(action?.expires_at) : '-'}</TableCell>

    </TableRow>
  );
}

export function QuoteHistory(props: Props) {
  const { error, actions } = props;
  if (error != null) {
    return (
      <Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    );
  }

  return (
    <Stack>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Event</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Expires</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {actions.map((action) => (
              <QuoteAction key={action.id} action={action} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
