import DoneIcon from '@mui/icons-material/Done';
import {
  Box, Button, Container, Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export function VerifySucceded() {
  const navigate = useNavigate();
  setTimeout(() => {
    navigate('/login');
  }, 3000);
  return (
    <Container maxWidth="lg">
      <Stack alignContent="center">
        <Stack direction="row" alignItems="center">
          <DoneIcon color="success" />
          <Box component="h3" ml="0.5rem">
            Your account has been verified
          </Box>
        </Stack>
        <Box>
          You will be redirected shortly.
          If you are not redirected, please click the button to login.
        </Box>
        <Box mt="2rem">
          <Button variant="contained" onClick={() => navigate('/login')}>Login</Button>
        </Box>
      </Stack>
    </Container>
  );
}
