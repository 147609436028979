import { Box } from '@mui/material';
import { NavigationBar } from '../components/NavigationBar';

interface Props {
  content: React.ReactNode;
}

export function AppLayout(props: Props) {
  const { content } = props;
  return (
    <Box display="flex" sx={{ flexFlow: 'column', height: '100vh' }}>
      <Box
        display="flex"
        sx={{
          backgroundColor: '#0d2e45', height: '100%', padding: '2rem 2rem 2rem 0', overflow: 'hidden',
        }}
      >
        <NavigationBar />
        <Box
          display="flex"
          sx={{
            flexFlow: 'column', width: '100%', overflowY: 'scroll', borderRadius: 4,
          }}
        >
          {content}
        </Box>
      </Box>
    </Box>
  );
}
