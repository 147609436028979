import SendIcon from '@mui/icons-material/Send';
import {
  Box, Button, Divider, Stack, Typography,
} from '@mui/material';
import { Map } from '../../../Map';
import { ShipmentDetails } from '../../../Shipment/components/ShipmentDetails';
import { ShipmentOverviewTab } from '../../../ShipmentOverviewTab';
import { ShipmentStatusBadge } from '../../../ShipmentStatusBadge';
import { SubmitQuoteForm } from '../SubmitQuoteForm';

interface Props {
  quoteSubmitFormOpen: boolean;
  onQuoteSubmitFormClose: VoidFunction
  shipment: CondensedShipment | undefined;
  onOpenSubmitQuote: VoidFunction;
}

export function ShipmentOverview(props: Props) {
  const {
    shipment, onOpenSubmitQuote, quoteSubmitFormOpen, onQuoteSubmitFormClose,
  } = props;

  return (
    <Box boxShadow={1}>
      <Map center={{ lat: 0, lng: 0 }} zoom={4} />
      <Box p="2rem">
        <Stack direction="row" alignItems="baseline" spacing={3}>
          <Typography variant="h5" mb="2rem">Shipment Details</Typography>
          <ShipmentStatusBadge status={shipment?.shipment_status} />
          {shipment?.shipment_status === 'ACTIVE' && <Button variant="contained" onClick={onOpenSubmitQuote} endIcon={<SendIcon />}>Submit Quote</Button>}
        </Stack>
        <ShipmentDetails shipment={shipment} />
        <Divider style={{ marginTop: '2rem' }} />
        <Box my="1rem">
          <ShipmentOverviewTab shipment={shipment} />
        </Box>
      </Box>
      {quoteSubmitFormOpen && shipment
      && <SubmitQuoteForm onClose={onQuoteSubmitFormClose} shipment={shipment} /> }
    </Box>
  );
}
