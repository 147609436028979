import { Box, Divider, Typography } from '@mui/material';
import { orderQuotesByQuoteStatus } from '../../../../utils';
import { ShipmentQuote } from '../../../ShipmentQuote';

interface Props {
  shipment: ShipperCondensedShipment;
}

export function ShipmentQuoteList(props: Props) {
  const {
    shipment,
  } = props;

  let quotesListComponent;
  if (shipment.condensed_quotes.length === 0) {
    quotesListComponent = (
      <Typography variant="body1" textAlign="center">
        You have not received any quotes on this shipment.
      </Typography>
    );
  } else {
    shipment.condensed_quotes = orderQuotesByQuoteStatus(shipment.condensed_quotes);
    quotesListComponent = (
      <>
        {shipment.condensed_quotes.map((quote) => (
          <div key={quote.quote}>
            <ShipmentQuote quote={quote} />
            <Box pt={1}>
              <Divider />
            </Box>
          </div>
        ))}
      </>
    );
  }

  return quotesListComponent;
}
