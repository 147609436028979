import {
  Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography,
} from '@mui/material';

interface Props {
  open: boolean
  onClose: VoidFunction;
  onCancelShipment: VoidFunction;
  error: string | null;
  loading: boolean;
}

export function CancelShipmentForm(props: Props) {
  const {
    open, onClose, onCancelShipment, error, loading,
  } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Cancel Shipment</DialogTitle>
      <DialogContent>
        {loading
          ? <Stack alignItems="center"><CircularProgress /></Stack>
          : <Typography>Are you sure you want to cancel this shipment?</Typography>}
        {error != null && !loading && <Typography color="red" align="center">{error}</Typography>}
      </DialogContent>
      {!loading && (
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onCancelShipment}>
          {!error ? 'OK' : 'retry'}
        </Button>
      </DialogActions>
      )}
    </Dialog>
  );
}
