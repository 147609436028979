import {
  Box,
  Button,
  CircularProgress,
  Stack, TextField, Typography,
} from '@mui/material';
import { CompanyResultsTable } from '../CompanyResultsTable';

interface Props {
  searchText: string;
  error: string | null;
  searchResults: Company[];
  onSearchChange: (value: string) => void;
  handleCreateCompanySelected: () => void;
  onSelectCompany: (companyID: number) => void;
  isLoading: boolean;
}

export function CompanySearchBar(props: Props) {
  const {
    searchText,
    searchResults,
    error, isLoading,
    onSearchChange,
    handleCreateCompanySelected,
    onSelectCompany,
  } = props;

  return (
    <Stack my="2rem" spacing={4} minWidth="200px">
      <Typography textAlign="center" variant="h4" fontWeight="bold">
        Find your company
      </Typography>
      <TextField
        value={searchText}
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder="Company name"
      />
      {!isLoading
      && (
      <CompanyResultsTable
        searchBegun={searchText.length > 0}
        companies={searchResults}
        onCompanySelected={onSelectCompany}
      />
      ) }
      {isLoading
        && (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      {error != null && <Typography color="red" align="center">{error}</Typography>}
      {
        searchResults.length === 0 && searchText.length > 0
        && <Button variant="contained" onClick={handleCreateCompanySelected}>Create Company</Button>
      }
      {
        searchResults.length === 0 && searchText.length === 0 && (
        <Stack direction="row" justifyContent="center" spacing={2} alignItems="center">
          <Typography variant="body1"><b>or</b></Typography>
          <Button variant="contained" onClick={handleCreateCompanySelected}>Create Company</Button>
        </Stack>
        )
      }
    </Stack>
  );
}
