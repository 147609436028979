import { useState } from 'react';
import { useAuthContext, usePasswordFieldToggle } from '../../../../hooks';
import { Login } from './Login';
import { LoginData } from './types';

export function LoginContainer() {
  const { login } = useAuthContext();
  const { passwordIsVisible, handleShowPasswordChange } = usePasswordFieldToggle();
  const [loginError, setLoginError] = useState<null | string>('');
  const [form, setForm] = useState<LoginData>({
    email: '',
    password: '',
  });

  const handleFormChange = (key: keyof LoginData, value: string) => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleLogin = () => {
    const { email: username, password } = form;
    if (form.email.length === 0 || form.password.length === 0) {
      setLoginError('Please enter your email and password');
    } else {
      setLoginError(null);
      login(username, password).catch(
        (e) => {
          if (e?.response?.status === 401) {
            setLoginError('Incorrect email or password.');
          } else {
            setLoginError('There was an error while signing in. Please contact us if the issue persists.');
          }
        },
      );
    }
  };

  return (
    <Login
      onLogin={handleLogin}
      onFormChange={handleFormChange}
      onShowPasswordChange={handleShowPasswordChange}
      showPassword={passwordIsVisible}
      loginError={loginError}
    />
  );
}
