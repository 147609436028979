import { NotificationModal } from './NotificationModal';

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

export function NotificationModalContainer(props: Props) {
  const { open, onClose } = props;

  return (
    <NotificationModal open={open} onClose={onClose} />
  );
}
