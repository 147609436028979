import CancelIcon from '@mui/icons-material/Cancel';
import {
  Box, Button, Divider, Stack, Typography,
} from '@mui/material';
import { Map } from '../../../Map';
import { ShipmentDetails } from '../../../Shipment/components/ShipmentDetails';
import { ShipmentOverviewTab } from '../../../ShipmentOverviewTab/ShipmentOverviewTab';
import { ShipmentStatusBadge } from '../../../ShipmentStatusBadge';

interface Props {
  shipment: CondensedShipment | undefined;
  onOpenCancelQuote: VoidFunction;
}

export function ShipmentOverview(props: Props) {
  const { shipment, onOpenCancelQuote } = props;

  return (
    <Box boxShadow={1}>
      <Map center={{ lat: 0, lng: 0 }} zoom={4} />
      <Box p="2rem">
        <Stack direction="row" alignItems="center" spacing={3} mb="2rem">
          <Typography variant="h5">Shipment Details</Typography>
          <ShipmentStatusBadge status={shipment?.shipment_status} />

          {shipment?.shipment_status === 'ACTIVE' && (
          <Button variant="contained" color="error" onClick={onOpenCancelQuote} endIcon={<CancelIcon />}>
            <Typography fontSize="0.8rem" fontWeight="bold">Cancel Shipment</Typography>
          </Button>

          )}
        </Stack>
        <ShipmentDetails shipment={shipment} />
        <Divider style={{ marginTop: '2rem' }} />
        <Box my="1rem">
          <ShipmentOverviewTab shipment={shipment} />
        </Box>
      </Box>
    </Box>
  );
}
