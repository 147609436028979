import { TextField } from '@mui/material';
import { DateTime } from 'luxon';

interface Props {
  label: string;
  onChange: (value: string) => void;
  initialValue?: string;
}

export function TimeInput(props: Props) {
  const { label, onChange, initialValue } = props;
  return (
    <TextField
      id="datetime-local"
      label={label}
      type="datetime-local"
      defaultValue={initialValue ?? DateTime.now().toFormat("yyyy-MM-dd'T'T")}
      sx={{ width: 250 }}
      InputLabelProps={{
        shrink: true,
      }}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
}

TimeInput.defaultProps = {
  initialValue: undefined,
};
