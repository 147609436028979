import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';

import { METER_MILE_CONVERSION_FACTOR } from '../constants';

interface HereRouteSection {
  summary: {
    length: number;
    duration: number;
    baseDuration: number;
  }
}

interface HereRoute {
  sections: HereRouteSection[]
}
interface RoutesResponse {
  routes: HereRoute[]
}

export function useRouteDistance(routes: RoutePoint[]): number {
  const [distance, setDistance] = useState<number | undefined>(undefined); // meters

  const buildRequestParams = useCallback(() => {
    const params = new URLSearchParams();
    params.set('apiKey', process.env.REACT_APP_HERE_API_APP_KEY ?? '');
    params.set('routingMode', 'short');
    params.set('transportMode', 'truck');
    params.set('return', 'summary');
    routes.forEach((route) => {
      if (route.point_location != null) {
        const { point_location: { latitude, longitude } } = route;
        if (latitude != null && longitude != null) {
          if (route.stop_type === 'ORIGIN') {
            params.set('origin', `${latitude},${longitude}`);
          }
          if (route.stop_type === 'DESTINATION') {
            params.set('destination', `${latitude},${longitude}`);
          }
          if (route.stop_type === 'MID') {
            params.append('via', `${latitude},${longitude}`);
          }
        }
      }
    });
    return params.toString();
  }, [routes]);

  const fetchRouteDistance = useCallback(() => {
    axios.get<RoutesResponse>(`https://router.hereapi.com/v8/routes?${buildRequestParams()}`).then((res) => {
      setDistance(res.data.routes[0].sections[0].summary.length);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildRequestParams]);

  useEffect(() => {
    fetchRouteDistance();
  }, [fetchRouteDistance]);

  if (distance == null) {
    return 0;
  }

  return distance / METER_MILE_CONVERSION_FACTOR;
}
