import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useEffect, useState } from 'react';
import { useAPI } from '../../../../hooks';
import { naiveTimestampToISOString, startLessOrEqualToEnd } from '../../../../utils';
import { SubmitQuoteForm } from './SubmitQuoteForm';
import { SubmitQuoteFormState } from './types';

const initialState: SubmitQuoteFormState = {
  equipmentType: '',
  pickupStart: '',
  pickupEnd: '',
  currency: '',
  price: 0.0,
};

interface Props {
  onClose: VoidFunction;
  shipment: CondensedShipment;
}

export function SubmitQuoteFormContainer(props: Props) {
  const { onClose, shipment } = props;
  const [error, setError] = useState<string | null>(null);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [quoteSubmitted, setQuoteSubmitted] = useState<boolean>(false);
  const { api } = useAPI();
  const [formState, setFormState] = useState<SubmitQuoteFormState>(initialState);
  // eslint-disable-next-line @typescript-eslint/comma-dangle
  const onValueChanged = <T,>(key: keyof SubmitQuoteFormState, value: T): void => {
    // validation here
    setFormState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const canSubmit = useCallback(() => {
    let ret = true;
    Object.entries(formState).forEach(([key, value]) => {
      if (value == null || value === undefined) {
        ret = false;
      }
      if (key === 'price' && (value === 0 || Number.isNaN(value))) {
        ret = false;
      } if (value === '') {
        ret = false;
      }
    });
    return ret;
  }, [formState]);

  useEffect(() => {
    if (loading) {
      if (!startLessOrEqualToEnd(formState.pickupStart, formState.pickupEnd)) {
        setError('Pickup start must be before or equal to Pickup end.');
        setIsLoading(false);
        return;
      }
      const body = {
        equipment_type: formState.equipmentType,
        pickup_start: naiveTimestampToISOString(formState.pickupStart),
        pickup_end: naiveTimestampToISOString(formState.pickupEnd),
        price: formState.price,
        currency: formState.currency,
      };
      setError(null);
      api<unknown>('post', `/shipments/${shipment.info.id}/quotes`, body).then(() => {
        setError(null);
        setQuoteSubmitted(true);
        const currUrlQuotesFocus = `${window.location.href}?focus=quotes`;
        window.location.href = currUrlQuotesFocus;
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to submit quote. Please contact us if the problem persists.');
        setQuoteSubmitted(false);
      });
      setIsLoading(false);
    }
  }, [loading, formState, api, shipment]);

  const onSubmit = () => {
    setIsLoading(true);
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Submit Quote</DialogTitle>
      <DialogContent>
        <SubmitQuoteForm
          onValueChanged={onValueChanged}
          toggleSubmitQuote={onClose}
          enableSubmit={canSubmit()}
          onSubmit={onSubmit}
          error={error}
          loading={loading}
          quoteSubmitted={quoteSubmitted}
        />
      </DialogContent>
    </Dialog>
  );
}
