import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAuthContext, useRoleContext } from '../../hooks';
import { useShipments } from '../../hooks/useShipments';
import { ShipmentOverview as CarrierShipmentOverview } from '../Carrier/components/ShipmentOverview';
import { ShipmentOverview as ShipperShipmentOverview } from '../Shipper/components/ShipmentOverview';
import { Shipment } from './Shipment';

export function ShipmentContainer() {
  const params = useParams();
  const shipmentId = params.id;
  const { user, isLoading } = useAuthContext();
  const { role } = useRoleContext();
  const { shipments } = useShipments();

  if (isLoading || user == null || role == null) {
    return <div />;
  }

  const selectedShipment = shipments.find((s) => s.info.id === parseInt(shipmentId ?? '0', 10));

  let shipmentOverviewComponent;
  switch (role.type) {
    case 'SHIPPER':
      shipmentOverviewComponent = (
        <ShipperShipmentOverview
          shipment={selectedShipment}
        />
      );
      break;
    case 'CARRIER':
      shipmentOverviewComponent = (
        <CarrierShipmentOverview
          shipment={selectedShipment}
        />
      );
      break;
    default:
      shipmentOverviewComponent = <Typography variant="h1">Invalid role. Please contact us for assistance.</Typography>;
      break;
  }

  return (
    <Shipment
      shipments={shipments}
      shipmentOverview={shipmentOverviewComponent}
    />
  );
}
