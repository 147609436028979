import { ShipmentQuoteList } from '../../../ShipmentQuoteList';

interface Props {
  shipment: CondensedShipment | undefined;
}

export function QuoteTab(props: Props) {
  const { shipment } = props;
  return (
    <ShipmentQuoteList shipment={shipment} />
  );
}
