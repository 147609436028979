import axios, { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import { useAuthContext } from './useAuthContext';

export function useAPI() {
  const { idToken, signout, refresh } = useAuthContext();

  const api = useCallback(async <T>(
    method: 'get' | 'post' | 'post' | 'patch' | 'delete',
    url: string,
    requestBody?: unknown,
  ): Promise<AxiosResponse<T>> => {
    if (idToken == null) {
      signout();
    }
    const config: AxiosRequestConfig = {
      method,
      url: process.env.REACT_APP_API_URL + url,
      headers: { Authorization: `Bearer ${idToken}` },
    };
    if (requestBody) {
      config.data = requestBody;
    }
    return axios.request(config).catch((e) => {
      /*
        Id token can expire if the user does not refresh
        their browser for more than one hour.
        Attempt to get the tokens and if success try the request again
      */
      if (e?.response?.status === 401) {
        refresh();
      }
      return axios.request(config);
    });
  }, [idToken, signout, refresh]);

  return { api };
}
