import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

export function PageLayout(props: PropsWithChildren<React.ReactNode>) {
  const { children } = props;

  return (
    <Box sx={{
      backgroundColor: '#fefefe',
      flex: 1,
      padding: '2rem',
      borderRadius: 4,
    }}
    >
      {children}
    </Box>
  );
}
