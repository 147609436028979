import { useRef, useEffect, useState } from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { useDeepCompareEffectForMaps } from '../../hooks';

interface MapProps extends google.maps.MapOptions {
  style?: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
}

export function Map(props: MapProps) {
  const {
    onClick, onIdle, style, ...options
  } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);

  // [START maps_react_map_component_options_hook]
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);
  // [END maps_react_map_component_options_hook]

  return (
    <div ref={ref} style={{ height: '400px', width: '100%', ...style }} />
  );
}

export function MapWrapper(props: MapProps) {
  return (
    <Wrapper apiKey={process.env.REACT_APP_GOOGLE_API_KEY ?? ''}>
      <Map {...props} />
    </Wrapper>

  );
}

const defaultMapProps = {
  style: {},
  onClick: () => null,
  onIdle: () => null,
};

MapWrapper.defaultProps = defaultMapProps;
Map.defaultProps = defaultMapProps;
