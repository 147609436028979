import CommuteOutlinedIcon from '@mui/icons-material/CommuteOutlined';
import ScaleOutlinedIcon from '@mui/icons-material/ScaleOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import WorkOutlineTwoToneIcon from '@mui/icons-material/WorkOutlineTwoTone';
import {
  Box, Stack, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatText, lbToKg } from '../../utils';

interface IconTextProps {
  icon: React.ReactNode;
  text: React.ReactNode;
}

function IconText(props: IconTextProps) {
  const { icon, text } = props;
  return (
    <Box display="flex" alignItems="center" width="45%" mr="0.5rem" mt="1rem">
      {icon}
      {text}
    </Box>
  );
}

interface Props {
  shipment: CondensedShipment;
}

export function CompactShipmentPreview(props: Props) {
  const { shipment } = props;
  const navigate = useNavigate();
  const shipmentInfo = shipment.info;
  const firstPointLocation = shipment?.first_point?.point_location;
  const lastPointLocation = shipment?.last_point?.point_location;
  return (
    <Box
      p="1rem"
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
        },
      }}
      onClick={() => navigate(`/shipments/${shipmentInfo.id}`)}
    >
      <Box display="flex" width="100%" flexWrap="wrap" mt="-1rem">
        <IconText
          icon={<CommuteOutlinedIcon />}
          text={<Typography sx={{ textTransform: 'capitalize', ml: '0.5rem' }}>{formatText(shipmentInfo.equipment_type)}</Typography>}
        />
        <IconText
          icon={<ScaleOutlinedIcon />}
          text={(
            <Typography sx={{ textTransform: 'capitalize', ml: '0.5rem' }}>
              {parseFloat(shipmentInfo.weight_lb.toString()).toFixed(1)}
              {' '}
              lb
              {' '}
              (
              {lbToKg(parseFloat(shipmentInfo.weight_lb.toString())).toFixed(1)}
              kg)
            </Typography>
            )}
        />
        <IconText
          icon={<WorkOutlineTwoToneIcon />}
          text={<Typography sx={{ textTransform: 'capitalize', ml: '0.5rem' }}>{formatText(shipmentInfo.service)}</Typography>}
        />
        <IconText
          icon={<SquareFootOutlinedIcon />}
          text={(
            <Typography sx={{ textTransform: 'capitalize', ml: '0.5rem' }}>
              {shipmentInfo.footage != null ? `${parseFloat(shipmentInfo.footage.toString()).toFixed(1)} ft` : '-'}
            </Typography>
          )}
        />
      </Box>
      <Stack direction="row" spacing={4} flexWrap="wrap">
        <Box py="1rem">
          <Typography fontSize="0.75rem" color="#b2b2b2">Origin</Typography>
          {firstPointLocation?.city && firstPointLocation?.province
            ? (
              <Typography>
                {firstPointLocation.city}
                ,
                {' '}
                {firstPointLocation.province}
              </Typography>
            ) : '- , -'}
        </Box>
        <Box py="1rem">
          <Typography fontSize="0.75rem" color="#b2b2b2">Destination</Typography>
          {lastPointLocation?.city && lastPointLocation?.province
            ? (
              <Typography>
                {lastPointLocation.city}
                ,
                {' '}
                {lastPointLocation.province}
              </Typography>
            ) : '- , -'}
        </Box>
        <Box py="1rem">
          <Typography fontSize="0.75rem" color="#b2b2b2">Shipment ID</Typography>
          <Typography variant="body1">{shipment?.info?.id ? `${shipment?.info?.id}` : '-'}</Typography>
        </Box>
      </Stack>
    </Box>
  );
}
