import { Delete } from '@mui/icons-material';
import {
  Card, CardActionArea, CardContent, CardHeader, IconButton, Typography,
} from '@mui/material';

export function NotificationCard() {
  return (
    <Card
      variant="outlined"
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
        },
      }}
    >
      <CardHeader
        action={(
          <IconButton>
            <Delete />
          </IconButton>
        )}
        title="Notification Title"
        subheader="how long ago??"
      />
      <CardActionArea>
        <CardContent>
          <Typography>Notification details</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
