import {
  Button, Grid, Stack, Typography,
} from '@mui/material';
import { QuoteActionModal } from './components/QuoteActionModal';
import { QuoteActionState } from './types';

interface Props {
  viewQuoteHistory: React.ReactNode;
  acceptQuote: QuoteActionState;
  counterQuote: QuoteActionState;
  cancelQuote: QuoteActionState;
  modalOpen: boolean;
  onCloseModal: VoidFunction;
  onActionClicked: (actionType: CondensedQuoteActionValue) => void;
  quoteId: number;
  modalAction: CondensedQuoteActionValue | null;
}

export function QuoteInteraction(props: Props) {
  const {
    viewQuoteHistory,
    acceptQuote,
    counterQuote,
    cancelQuote,
    modalOpen,
    onCloseModal,
    onActionClicked,
    quoteId,
    modalAction,
  } = props;

  return (
    <>
      {modalOpen
        ? <QuoteActionModal quoteId={quoteId} action={modalAction} onClose={onCloseModal} />
        : null}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid
          container
          item
          xs={8}
          sm={8}
          md={9}
          lg={9}
          xl={10}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          {(acceptQuote.isDisplayed) && (
            <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
              <Button
                color="success"
                fullWidth
                variant="contained"
                onClick={() => onActionClicked(acceptQuote.actionType)}
              >
                <Typography fontSize="0.8rem" fontWeight="bold">Book</Typography>
              </Button>
            </Grid>
          )}
          {(counterQuote.isDisplayed) && (
            <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                onClick={() => onActionClicked(counterQuote.actionType)}
              >
                <Typography fontSize="0.8rem" fontWeight="bold">Counter</Typography>
              </Button>
            </Grid>
          )}
          {(cancelQuote.isDisplayed) && (
            <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
              <Button
                color="warning"
                fullWidth
                variant="contained"
                onClick={() => onActionClicked(cancelQuote.actionType)}
              >
                <Typography fontSize="0.8rem" fontWeight="bold">Cancel</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item xs={4} sm={4} md={3} lg={3} xl={2}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
          >
            {viewQuoteHistory}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
