import {
  CircularProgress, Divider, Stack, Typography,
} from '@mui/material';
import { ShipmentSearch } from '../ShipmentSearch';

interface Props {
  shipmentSearches: ShipmentSearch[];
  loading: boolean;
  error: string | null;
  isOpen: boolean;
  onSavedSearchClick: VoidFunction;
}

export function ShipmentSearchList(props: Props) {
  const {
    shipmentSearches, loading, error, isOpen, onSavedSearchClick,
  } = props;
  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  if (shipmentSearches.length === 0) {
    return (
      <Typography variant="body1" textAlign="center">
        You do not have any saved searches.
      </Typography>
    );
  }
  if (error != null) {
    return (
      <Stack>
        <Typography color="red" align="center">{error}</Typography>
      </Stack>
    );
  }
  return (
    <div>
      {isOpen && shipmentSearches.map((shipmentSearch) => (
        <div key={shipmentSearch.id}>
          <ShipmentSearch shipmentSearch={shipmentSearch} onSavedSearchClick={onSavedSearchClick} />
          <Divider />
        </div>
      ))}
    </div>
  );
}
