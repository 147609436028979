import { useAPI, useDisclosure, useRoleContext } from 'hooks';
import { useShipmentFiltersContext } from 'hooks/useShipmentFiltersContext';
import { useState } from 'react';
import { naiveTimestampToISOString } from 'utils';
import { ShipmentSaveSearch } from './ShipmentSaveSearch';

export function ShipmentSaveSearchContainer() {
  const { filters } = useShipmentFiltersContext();
  const { api } = useAPI();
  const { role } = useRoleContext();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>('');
  const [searchName, setSearchName] = useState<string>('');

  const onChangeSearchName = (name: string) => {
    setSearchName(name);
  };

  const onSubmitSaveSearch = () => {
    if (role !== null) {
      setIsLoading(true);
      const body: CreateShipmentSearch = {
        name: searchName,
        equipment_type: filters?.equipment_type,
        service: filters?.service,
        origin_city: filters?.origin?.city,
        origin_province: filters?.origin?.province,
        origin_country: filters?.origin?.country,
        destination_city: filters?.destination?.city,
        destination_province: filters?.destination?.province,
        destination_country: filters?.destination?.country,
        // TODO:
        // These will be replaced with their place ids once the AddressAutocomplete is correctly
        // integrated into the ShipmentFilter components.
        pickup_start: filters?.pickup_window?.pickup_start
          ? naiveTimestampToISOString(filters.pickup_window.pickup_start)
          : undefined,
        pickup_end: filters?.pickup_window?.pickup_end
          ? naiveTimestampToISOString(filters.pickup_window.pickup_end)
          : undefined,
        commodity: filters?.commodity?.code,
      };
      api<unknown>('post', `/carriers/${role.id}/shipment-searches`, body).then(() => {
        // Probably need to reload the window so that the saved searches list can update
        // Unfortunately, that means that user is going to lose their filters and have to
        // select the search again.
        // TODO: More persistence so we can detect whether the user had the list of
        // saved searches open (vs list of shipments) and persistence so
        // that we can populate them back into the filter inputs from local storage.
        setError(null);
        setSearchName('');
        onClose();
        window.location.href = `${window.location.href}`;
      }).catch((e) => {
        setError(e?.response?.data?.message || 'Failed to save search');
      }).finally(() => { setIsLoading(false); });
    }
  };

  return (
    <ShipmentSaveSearch
      saveSearchModalIsOpen={isOpen}
      searchName={searchName}
      onCloseModal={onClose}
      onOpenModal={onOpen}
      onSubmitSaveSearch={onSubmitSaveSearch}
      handleChangeSearchName={onChangeSearchName}
      submitSaveSearchIsLoading={isLoading}
      submitSaveSearchError={error}
    />
  );
}
