import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box, IconButton, Stack, Typography,
} from '@mui/material';
import { useShipmentFiltersContext } from 'hooks/useShipmentFiltersContext';
import { formatText, ISOStringToLocalReadableTimestamp } from 'utils';

interface Props {
  shipmentSearch: ShipmentSearch;
  onOpenDeleteShipmentSearch: VoidFunction;
  onClick: VoidFunction;
}

export function ShipmentSearch(props: Props) {
  const { shipmentSearch, onOpenDeleteShipmentSearch, onClick } = props;
  const { clearFilters, setFilters } = useShipmentFiltersContext();

  const onSeachClick = () => {
    clearFilters();
    const savedSearch: ShipmentFilters = {
      equipment_type: shipmentSearch.equipment_type ? shipmentSearch.equipment_type : '',
      service: shipmentSearch.service ? shipmentSearch.service : '',
      commodity: shipmentSearch.commodity ? shipmentSearch.commodity : undefined,
      status: 'ACTIVE',
      origin: {
        city: shipmentSearch.origin_city ? shipmentSearch.origin_city : '',
        province: shipmentSearch.origin_province ? shipmentSearch.origin_province : '',
        country: shipmentSearch.origin_country ? shipmentSearch.origin_country : '',
      },
      destination: {
        city: shipmentSearch.destination_city ? shipmentSearch.destination_city : '',
        province: shipmentSearch.destination_province ? shipmentSearch.destination_province : '',
        country: shipmentSearch.destination_country ? shipmentSearch.destination_country : '',
      },
      pickup_window: {
        pickup_start: shipmentSearch.pickup_start ? shipmentSearch.pickup_start : '',
        pickup_end: shipmentSearch.pickup_end ? shipmentSearch.pickup_end : '',
      },
      order_by: '-created_at',
    };
    setFilters(savedSearch);
    onClick();
  };

  return (
    <Box
      display="flex"
      px="2rem"
      py="1rem"
      sx={{
        '&:hover': {
          bgcolor: '#f8f8f8',
          cursor: 'pointer',
        },
      }}
    >
      <Box flex={1} onClick={() => onSeachClick()}>
        <Stack direction="row">
          <Box>
            <Stack my="0.3rem" direction="row" alignItems="center" spacing={4}>
              <Typography>
                Name:
                {' '}
                {shipmentSearch.name}
              </Typography>
              <Typography>{shipmentSearch.equipment_type ? `Equipment: ${formatText(shipmentSearch.equipment_type)}` : ''}</Typography>
              <Typography>{shipmentSearch.service ? `Service: ${formatText(shipmentSearch.service)}` : ''}</Typography>
            </Stack>
            <Stack my="0.5rem" direction="row" alignItems="center" spacing={2}>
              <Typography>{shipmentSearch.commodity ? `Commodity: ${shipmentSearch.commodity.name}` : ''}</Typography>
            </Stack>
            <Stack my="0.5rem" direction="row" alignItems="center" spacing={2}>
              <Typography>{shipmentSearch.origin_city ? `Origin City: ${shipmentSearch.origin_city}` : ''}</Typography>
              <Typography>{shipmentSearch.origin_province ? `Origin Province/State: ${shipmentSearch.origin_province}` : ''}</Typography>
              <Typography>{shipmentSearch.origin_country ? `Origin Country: ${shipmentSearch.origin_country}` : ''}</Typography>
            </Stack>
            <Stack my="0.5rem" direction="row" alignItems="center" spacing={2}>
              <Typography>{shipmentSearch.destination_city ? `Destination City: ${shipmentSearch.destination_city}` : ''}</Typography>
              <Typography>{shipmentSearch.destination_province ? `Destination Province/State: ${shipmentSearch.destination_province}` : ''}</Typography>
              <Typography>{shipmentSearch.destination_country ? `Destination Country: ${shipmentSearch.destination_country}` : ''}</Typography>
            </Stack>
            <Stack my="0.5rem" direction="row" alignItems="center" spacing={2}>
              <Typography>{shipmentSearch.pickup_start ? `Pickup Start: ${ISOStringToLocalReadableTimestamp(shipmentSearch.pickup_start)}` : ''}</Typography>
              <Typography>{shipmentSearch.pickup_end ? `Pickup End: ${ISOStringToLocalReadableTimestamp(shipmentSearch.pickup_end)}` : ''}</Typography>
            </Stack>
            <Typography variant="body2" color="text.secondary">
              Created at:
              {' '}
              {ISOStringToLocalReadableTimestamp(shipmentSearch.created_at)}
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box alignItems="center" justifyContent="center" display="flex">
        <IconButton onClick={onOpenDeleteShipmentSearch}>
          <DeleteIcon fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
}
