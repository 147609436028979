import { throttle } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getPlaceDetails, getPlacePredictions } from '../../utils/googlePlacesApi';
import { AddressAutocomplete } from './AddressAutocomplete';
import { PlaceDetailsFields } from './constants';
import { PlaceType } from './types';

interface Props {
  onChange?: (value: string) => void;
  onAddressSelected?: (address: google.maps.places.PlaceResult | null) => void;
  addressTypes?: Array<string>;
  useAddressLineValue?: boolean;
  inputLabelTitle?: string;
  isRequired?: boolean;
}

export function AddressAutocompleteContainer(props: Props) {
  const {
    onChange,
    // TODO: Merge onAddressSelected and onChange into one
    onAddressSelected,
    useAddressLineValue,
    addressTypes,
    inputLabelTitle,
    isRequired,
  } = props;
  const [value, setValue] = useState<PlaceType | null>(null);
  const [inputValue, setInputValue] = useState<string>('');
  const [options, setOptions] = useState<readonly PlaceType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [predictionsHasError, setPredictionsHasError] = useState<boolean>(false);
  const [predictionsErrorText, setPredictionsErrorText] = useState<string>('');

  const fetch = useMemo(
    () => throttle(
      (
        request: { input: string },
        active: boolean,
      ) => {
        setIsLoading(true);
        getPlacePredictions(request.input, addressTypes).then((predictions) => {
          if (active) {
            let newPlacePredictions: readonly PlaceType[] = [];
            if (value) {
              newPlacePredictions = [value];
            }
            if (predictions) {
              newPlacePredictions = [...newPlacePredictions, ...predictions];
            }

            setOptions(newPlacePredictions);
            setIsLoading(false);
            setPredictionsHasError(false);
          }
        }).catch(() => {
          setPredictionsErrorText('Unable to load addresses');
          setPredictionsHasError(true);
          setIsLoading(false);
        });
      },
      200,
    ),
    [value, addressTypes],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch({ input: inputValue }, active);

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  useEffect(() => {
    if (value && value.place_id && onAddressSelected) {
      getPlaceDetails(value.place_id, [PlaceDetailsFields.ADDRESS_COMPONENTS])
        .then((placeDetailsResult) => {
          onAddressSelected(placeDetailsResult);
        });
    }
  }, [value, onAddressSelected]);

  const handleChange = (newValue: PlaceType | null) => {
    if (onChange) {
      onChange(newValue?.place_id ?? '');
    }
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
  };

  const onInputChange = (newInputValue: string) => {
    setInputValue(newInputValue);
  };

  return (
    <AddressAutocomplete
      isLoading={isLoading}
      hasError={predictionsHasError}
      errorText={predictionsErrorText}
      placeOptions={options}
      autocompleteValue={value}
      onChange={handleChange}
      onInputChange={onInputChange}
      useOptionMainText={useAddressLineValue}
      inputLabelTitle={inputLabelTitle}
      isRequired={isRequired}
    />
  );
}

AddressAutocompleteContainer.defaultProps = {
  onChange: undefined,
  onAddressSelected: undefined,
  useAddressLineValue: false,
  addressTypes: [],
  inputLabelTitle: 'Address',
  isRequired: false,
};
