import {
  Box,
} from '@mui/material';
import { useDisclosure } from '../../../../hooks';
import { ShipmentOverview } from './ShipmentOverview';

interface Props{
  shipment: CondensedShipment | undefined;
}

export function ShipmentOverviewContainer(props: Props) {
  const { shipment } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box>
      <ShipmentOverview
        quoteSubmitFormOpen={isOpen}
        onQuoteSubmitFormClose={onClose}
        shipment={shipment}
        onOpenSubmitQuote={onOpen}
      />
    </Box>
  );
}
