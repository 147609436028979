import {
  Box, Divider, Stack, Typography,
} from '@mui/material';
import { ShipmentPreview } from '../ShipmentPreview';

interface Props {
  shipments: CondensedShipment[]
}

export function ShipmentsList(props: Props) {
  const { shipments } = props;
  if (shipments.length === 0) {
    return (
      <Stack direction="column" justifyContent="center">
        <Typography sx={{ mt: '4rem' }} textAlign="center" variant="body1">You have no shipments for now.</Typography>
      </Stack>
    );
  }
  return (
    <Stack spacing={2}>
      {shipments.map((shipment) => (
        <Box key={shipment.info.id}>
          <ShipmentPreview shipment={shipment} />
          <Divider style={{ marginTop: '0' }} />
        </Box>
      ))}
    </Stack>
  );
}
