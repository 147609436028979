import {
  Box,
} from '@mui/material';
import { useDisclosure } from '../../../../hooks';
import { CancelShipmentForm } from '../CancelShipmentForm';
import { ShipmentOverview } from './ShipmentOverview';

interface Props{
  shipment: CondensedShipment | undefined;
}

export function ShipmentOverviewContainer(props: Props) {
  const { shipment } = props;
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box>
      <ShipmentOverview
        shipment={shipment}
        onOpenCancelQuote={onOpen}
      />
      {shipment !== undefined
      && <CancelShipmentForm open={isOpen} onClose={onClose} shipment={shipment} />}
    </Box>
  );
}
