import {
  Autocomplete, TextField, TextFieldProps,
} from '@mui/material';
import { useAPI } from 'hooks';
import { useEffect, useState } from 'react';
import { FindCommodityResponse } from './types';

const DEFAULT_COMMODITY_LIMIT = '15';

interface Props {
  onCommoditySelected: (newValue: Commodity | null) => void;
  commodityValue: Commodity | null;
  textFieldProps: TextFieldProps;
}

export function CommoditySelectInput(props: Props) {
  const { onCommoditySelected, textFieldProps, commodityValue } = props;
  const [search, setSearch] = useState<string | null>(null);
  const [commodities, setCommodities] = useState<Commodity[]>([]);
  const { api } = useAPI();

  useEffect(() => {
    if (search != null) {
      const params = new URLSearchParams({
        name: search,
        limit: DEFAULT_COMMODITY_LIMIT,
      }).toString();
      api<FindCommodityResponse>('get', `/commodities?${params}`).then((response) => {
        setCommodities(response.data.results);
      }).catch(() => {
        /*
        If there is an error, display the 'failsafe' option.
        This way we avoid stalling the user from doing whatever action
        requires a commodity.
        */
        setCommodities([{ code: '0', name: 'General' }]);
      });
    }
  }, [api, search]);

  return (
    <Autocomplete
      disablePortal
      options={commodities}
      sx={{ width: 300 }}
      value={commodityValue}
      getOptionLabel={(option) => (option ? `${option.name} (code ${option.code})` : '')}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === 'clear') {
          onCommoditySelected(null);
        }
        // the actual value on the rendered text field
        setSearch(newInputValue);
      }}
      onChange={(event: unknown, newValue: Commodity | null) => {
        if (newValue != null) {
          // called when an option on the autocomplete is
          // selected: contains only the commodity code
          onCommoditySelected(newValue);
        }
      }}
      renderInput={(params) => <TextField {...params} {...textFieldProps} label="Commodity" />}
    />
  );
}
