import {
  AccountCircle, LocalShippingOutlined, MeetingRoom, Notifications,
} from '@mui/icons-material';
import {
  Drawer, IconButton, Stack, Tooltip, Zoom,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NotificationModal } from 'components/Notifcation/components/NotificationModal';
import { useNavigate } from 'react-router-dom';
import { useAuthContext, useDisclosure } from '../../hooks';
import { SwitchRoleButton } from '../SwitchRoleButton';

const drawerWidth = 100;

const DrawerHeader = styled('div')(({ theme }) => ({
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
}));

export function NavigationBar() {
  const navigate = useNavigate();
  const { signout } = useAuthContext();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <Drawer
        variant="permanent"
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#0d2e45',
          },
        }}
      >
        <DrawerHeader>
          <img
            style={{
              height: '80px', width: '80px', paddingTop: '1rem',
            }}
            src="/images/e_logo.jpg"
            alt="badge logo"
          />
        </DrawerHeader>
        <Stack alignItems="center" color="white" py="2rem" spacing={1}>
          <Tooltip title="Dashboard" placement="right" TransitionComponent={Zoom}>
            <IconButton color="inherit" onClick={() => navigate('/')}>
              <LocalShippingOutlined fontSize="large" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications" placement="right" TransitionComponent={Zoom}>
            <IconButton color="inherit" onClick={onOpen}>
              <Notifications fontSize="large" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Account" placement="right" TransitionComponent={Zoom}>
            <IconButton color="inherit">
              <AccountCircle fontSize="large" />
            </IconButton>
          </Tooltip>
          <SwitchRoleButton />
          <Tooltip title="Log out" placement="right" TransitionComponent={Zoom}>
            <IconButton color="inherit" onClick={signout}>
              <MeetingRoom fontSize="large" />
            </IconButton>
          </Tooltip>
        </Stack>
      </Drawer>
      <NotificationModal open={isOpen} onClose={onClose} />
    </>
  );
}
