import { Delete, MarkEmailRead } from '@mui/icons-material';
import {
  Button,
  Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography,
} from '@mui/material';
import { NotificationCard } from '../NotificationCard/NotificationCard';

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

export function NotificationModal(props: Props) {
  const { open, onClose } = props;

  return (
    <Dialog open={open} onClose={onClose} fullWidth sx={{ m: '1rem' }}>
      <DialogTitle sx={{ fontSize: '1.5rem' }}>
        Notifications
        <Button sx={{ mx: '2rem' }} variant="contained" color="warning" endIcon={<MarkEmailRead />}>
          <Typography fontSize="0.8rem">Mark all as read</Typography>
        </Button>
        <Button variant="contained" color="error" endIcon={<Delete />}>
          <Typography fontSize="0.8rem">Delete all</Typography>
        </Button>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
          <NotificationCard />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
