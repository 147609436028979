import * as React from 'react';
import { roleContext } from '../context';
import { useAuthContext } from './useAuthContext';

const LOCAL_STORAGE_ROLE_KEY = 'role';

export function useRoleContext() {
  const roleCtx = React.useContext(roleContext);
  const { user } = useAuthContext();

  function getLocalRole(): Role | null {
    const role = localStorage.getItem(LOCAL_STORAGE_ROLE_KEY);
    if (role) {
      return JSON.parse(role);
    }
    return null;
  }

  function saveRoleToLocal(role: Role) {
    localStorage.setItem(LOCAL_STORAGE_ROLE_KEY, JSON.stringify(role));
  }

  function switchToRole(role: Role) {
    saveRoleToLocal(role);
    roleCtx.dispatch({ type: 'setRole', role });
    window.location.href = '/';
  }

  // gets any role from the list of roles that the user has
  function getAnyRole(): Role | null {
    if (user != null) {
      const foundRole = user.roles.find((role) => (role.type === 'CARRIER' || role.type === 'SHIPPER'));
      if (!foundRole) {
        return null;
      }
      return foundRole;
    }
    return null;
  }

  React.useEffect(() => {
    if (user == null) {
      roleCtx.dispatch({ type: 'clearRole' });
      localStorage.removeItem(LOCAL_STORAGE_ROLE_KEY);
    } else if (roleCtx.role == null) {
      const localRole = getLocalRole();
      if (localRole == null) {
        if (getAnyRole() != null) {
          roleCtx.dispatch({ type: 'setRole', role: getAnyRole() as Role });
          saveRoleToLocal(getAnyRole() as Role);
        }
        // if the ctx has no role, local storage does not have a role and
        // the user has no role then there's nothing to do.
      } else {
        roleCtx.dispatch({ type: 'setRole', role: localRole });
      }
    }
  });
  return {
    role: roleCtx.role,
    company: roleCtx.company,
    switchToRole,
  };
}
