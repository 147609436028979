import {
  Box,
} from '@mui/material';
import { Select } from '../Select';

interface Props {
  onChange: (value: string) => void;
}

export function CurrencySelect(props: Props) {
  const { onChange } = props;
  return (
    <Box mr="1rem" mt="1rem">
      <Select<string>
        inputLabel="Currency"
        defaultValue=""
        onChange={onChange}
        options={[
          {
            value: 'CAD',
            label: 'CAD',
          },
          {
            value: 'USD',
            label: 'USD',
          },
        ]}
      />
    </Box>
  );
}
