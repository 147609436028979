import { Box } from '@mui/material';
import { QuoteDetail } from './components/QuoteDetail';
import { QuoteHistory } from './components/QuoteHistory';
import { QuoteInteraction } from './components/QuoteInteraction';

interface Props {
  quote: CondensedQuote;
  historyIsExpanded: boolean;
  viewQuoteHistory: React.ReactNode;
}

export function ShipmentQuote(props: Props) {
  const {
    quote,
    historyIsExpanded,
    viewQuoteHistory,
  } = props;

  return (
    <Box pt="1rem">
      <QuoteDetail
        quote={quote}
      />
      <Box pt="1rem">
        <QuoteInteraction
          quoteId={quote.quote}
          viewQuoteHistory={viewQuoteHistory}
          actions={quote.actions}
        />
      </Box>
      <Box mt="1rem">
        {(historyIsExpanded) && (
          <QuoteHistory quoteId={quote.quote} />
        )}
      </Box>
    </Box>
  );
}
